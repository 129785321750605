import React ,{useState} from 'react'; 
import AuthenticationStatus from '../../Middleware/AuthMiddleware';
import axios from 'axios';
const Dashboard = () => {
    AuthenticationStatus();
    var userdata = JSON.parse(sessionStorage.getItem('LoggedInUser'));
    const [startspinner, setstartspinner] = useState('');
    setTimeout(() => {
        setstartspinner('stopdashloader');
      }, 2000)
   
    const [data, setData] = useState([]);
    
    React.useEffect(() => {
        
        axios({
            method: "post",
            url: global.config.ApiUri+"getuserscounts",
            data: '',
            headers: {'Content-Type': 'application/json','Authorization': `Bearer ${userdata.access_token}`},
            })
            .then(function (response) {
                // console.log(response.data.data)
                switch (response.status) {
                    case 200:
                        setData(response.data.data);
                        break;
                    default:
                        break;
                }
            })
            .catch(function (response) {
            });
      }, []);
    return (
       
        <>
            
         
                {/*
                <!-- Content Header (Page header) -->*/}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Dashboard</h1>
                            </div>
                            {/*
                            <!-- /.col -->*/}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Dashboard</li>
                                </ol>
                            </div>
                            {/*
                            <!-- /.col -->*/}
                        </div>
                        {/*
                        <!-- /.row -->*/}
                    </div>
                    {/*
                    <!-- /.container-fluid -->*/}
                </div>
                {/*
                <!-- /.content-header -->

                <!-- Main content -->*/}
                {userdata.role != 'Admin'?'':
                <section className="content">
                    <div className="container-fluid">
                        {/*
                        <!-- Small boxes (Stat box) -->*/}
                        <div className="row">
                            <div className="col-lg-3 col-6">
                                {/*
                                <!-- small box -->*/}
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{data.length>0? data[0].totalusers:0}</h3>

                                        <p>No. of all Users</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-users"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                </div>
                            </div>
                            {/*
                            <!-- ./col -->*/}
                            <div className="col-lg-3 col-6">
                                {/*
                                <!-- small box -->*/}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{data.length>0? data[0].students:0}</h3>

                                        <p>No. of Students</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-users"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                </div>
                            </div>
                            {/*
                            <!-- ./col -->*/}
                            <div className="col-lg-3 col-6">
                                {/*
                                <!-- small box -->*/}
                                <div className="small-box bg-warning">
                                    <div className="inner">
                                        <h3>{data.length>0? data[0].employers:0}</h3>

                                        <p>No. of Employers</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-users"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                </div>
                            </div>
                            {/*
                            <!-- ./col -->*/}
                            <div className="col-lg-3 col-6">
                                {/*
                                <!-- small box -->*/}
                                <div className="small-box bg-danger">
                                    <div className="inner">
                                        <h3>0</h3>

                                        <p>No. of Purchased</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-rupee-sign"></i>
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                                </div>
                            </div>
                            {/*
                            <!-- ./col -->*/}
                        </div>
                        {/*
                        <!-- /.row -->*/} {/*
                        <!-- Main row -->*/} {/*
                        <!-- /.row (main row) -->*/}
                    </div>
                    {/*
                    <!-- /.container-fluid -->*/}
                </section>
}
                {/*
                <!-- /.content -->*/}
         
        </>
    );
}

export default Dashboard;
