import React ,{ useState } from 'react';
import { Link } from 'react-router-dom';

const DashboardHeader = () => {
    const [startspinner, setstartspinner] = useState('startspinnercls');
    setTimeout(() => {
        setstartspinner('stopspinnercls') ;
      }, 2000)
  
  // console.log(userdata.name);

    return (
        <>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css" integrity="sha512-Kc323vGBEqzTmouAECnVceyQqyqdsSiqLQISBL29aUW4U/M7pSPA/gEUZQqv1cwx4OnYxTxve5UMg5GT6L4JJg==" crossOrigin="anonymous" referrerPolicy="no-referrer" />
         {/* <div id="spinner" className={` show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center ${startspinner} `}>
            <div className="spinner-border text-primary"  role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div> */}
        
         <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback"/>
        
         <link rel="stylesheet" href="dashboard assets/plugins/fontawesome-free/css/all.min.css"/>
         
        <link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css"/>
        
        <link rel="stylesheet" href="dashboard assets/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css"/>
        
        <link rel="stylesheet" href="dashboard assets/plugins/icheck-bootstrap/icheck-bootstrap.min.css"/>
       
        <link rel="stylesheet" href="dashboard assets/plugins/jqvmap/jqvmap.min.css"/>
       
        <link rel="stylesheet" href="dashboard assets/dist/css/adminlte.min.css"/>
        <link rel="stylesheet" href="dashboard assets/dist/css/preloadercss.css"/>
        <link rel="stylesheet" href="dashboard assets/dist/css/custom.css?v=3725896fsdfj"/>
       
        <link rel="stylesheet" href="dashboard assets/plugins/overlayScrollbars/css/OverlayScrollbars.min.css"/>
       
        <link rel="stylesheet" href="dashboard assets/plugins/daterangepicker/daterangepicker.css"/>
        
      {/*  <link rel="stylesheet" href="dashboard assets/plugins/summernote/summernote-bs4.min.css"> */}
        
        <link rel="stylesheet" href="dashboard assets/dist/dashboardcss.css"/>
       
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            {/* <!-- Left navbar links --> */}
            <ul className="navbar-nav">
            <li className="nav-item">
                <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
            </li>
            {/* <li className="nav-item d-none d-sm-inline-block">
                <Link to="/" className="nav-link">Home</Link>
            </li> */}
            {/* <li className="nav-item d-none d-sm-inline-block">
                <a href="#" className="nav-link">Contact</a>
            </li> */}
            </ul>

            {/* <!-- Right navbar links --> */}
            <ul className="navbar-nav ml-auto">
            {/* <!-- Navbar Search --> */}
            <li className="nav-item">
                <a className="nav-link" data-widget="navbar-search" href="#" role="button">
                <i className="fas fa-search"></i>
                </a>
                <div className="navbar-search-block">
                <form className="form-inline">
                    <div className="input-group input-group-sm">
                    <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search"/>
                    <div className="input-group-append">
                        <button className="btn btn-navbar" type="submit">
                        <i className="fas fa-search"></i>
                        </button>
                        <button className="btn btn-navbar" type="button" data-widget="navbar-search">
                        <i className="fas fa-times"></i>
                        </button>
                    </div>
                    </div>
                </form>
                </div>
            </li>

            {/* <!-- Messages Dropdown Menu --> */}
           
            {/* <!-- Notifications Dropdown Menu --> */}
            <li className="nav-item dropdown">
                <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="far fa-bell"></i>
                <span className="badge badge-warning navbar-badge">0</span>
                </a>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span className="dropdown-item dropdown-header">15 Notifications</span>
                <div className="dropdown-divider"></div>
                <a href="#" className="dropdown-item">
                    <i className="fas fa-envelope mr-2"></i> 4 new messages
                    <span className="float-right text-muted text-sm">3 mins</span>
                </a>
                <div className="dropdown-divider"></div>
                <a href="#" className="dropdown-item">
                    <i className="fas fa-users mr-2"></i> 8 friend requests
                    <span className="float-right text-muted text-sm">12 hours</span>
                </a>
                <div className="dropdown-divider"></div>
                <a href="#" className="dropdown-item">
                    <i className="fas fa-file mr-2"></i> 3 new reports
                    <span className="float-right text-muted text-sm">2 days</span>
                </a>
                <div className="dropdown-divider"></div>
                <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
                </div>
            </li>
            <li className="nav-item">
                <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                <i className="fas fa-expand-arrows-alt"></i>
                </a>
            </li>
            
            </ul>
        </nav>
        {/* <!-- /.navbar --> */}
    
       
        </>
    );
}
export default DashboardHeader;
