import React ,{ useState } from 'react';
import { useFormInputValidation } from "react-form-input-validation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  { useNavigate } from "react-router-dom";
import axios from 'axios';

const AddSections = () => {
    var userdata = JSON.parse(sessionStorage.getItem('LoggedInUser'));
    const [checked, setChecked] = useState(false);
    const [mandatechecked, setmandatechecked] = useState(false);
    const navigate = useNavigate();
  const { usertype   } = false;
  const [startspinner, setstartspinner] = useState('stopspinnercls');
  const [btndisable, setbtndisable] = useState('');
  const [data, setData] = useState([])
  React.useEffect(() => {
    const sessiontoken = JSON.parse(sessionStorage.getItem('LoggedInUser'));
    axios({
        method: "post",
        url: global.config.ApiUri+"getcourses",
        data: '',
        headers: {'Content-Type': 'application/json','Authorization': `Bearer ${sessiontoken.access_token}`},
        })
        .then(function (response) {
            // console.log(response.data.data)
            switch (response.status) {
                case 200:
                    setData(response.data.data);
                    break;
                default:
                    break;
            }
        })
        .catch(function (response) {
        });
  }, []);
    const [fields, errors, form] = useFormInputValidation({
        course: "",
        title: "",
        description: "",
        mandatory:"",
        status_id:checked
      }, {
        course: "required",
        title: "required",
        description: "required",
        mandatory: "required",
        status_id: "required"
      });
    
      const onSubmitSection = async (event) => {
        // console.log(fields)
        fields.status_id=checked;
        fields.mandatory=mandatechecked;
        
        const isValid = await form.validate(event);
        if (isValid) {
          setstartspinner('startspinnercls') ;
          // console.log(fields, errors);
              axios({
              method: "post",
              url: global.config.ApiUri+"addsection",
              data: fields,
              headers: {
                "Accept": "application/json",
                 "Authorization": `Bearer ${userdata.access_token}`,
                },
              })
              .then(function (response) {
                  //handle success
                  // console.log(response)
                  switch (response.status) {
                      case 200:
                          toast.success(response.data.data.message);
                          setbtndisable('disablebtn');
                          setstartspinner('stopspinnercls') ;
                          break;
                      default:
                          break;
                  }
              })
              .catch(function (response) {
                  //handle error
                  // console.log(response);
                  switch (response.status) {
                      case 422:
                          toast.error(response.response.data.message);
                          setstartspinner('stopspinnercls') ;
                          break;
                      default:
                          break;
                  }
              });
          }
      }
      const changestatus=()=>{
        setChecked(!checked); 
            {checked ? (fields.status_id = false) : (fields.status_id = true)}
      }
      const changestatusMandate=()=>{
        setmandatechecked(!checked); 
            {mandatechecked ? (fields.mandatory = false) : (fields.mandatory = true)}
      }
      if (!toast.isActive(13, "friendRequest")) {
        toast({
          autoClose: 1000,
          toastId: 13                      
      })
      }
    return (
        <>
        
        {/* <div className="preloader flex-column justify-content-center align-items-center">
            <img className="animation__shake" src="dashboard assets/dist/img/logo 1.png" alt="AdminLTELogo"  width="250"/>
        </div> */}
        <ToastContainer autoClose={1000} containerId={"friendRequest"} />
            {/* <!-- Content Header (Page header) --> */}
            <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>Add Sections</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="../../index.html">Home </a></li>
                        <li className="breadcrumb-item active">Add Sections</li>
                        </ol>
                    </div>
                </div>
            </div>
            {/* <!-- /.container-fluid --> */}
            </section>

            {/* <!-- Main content --> */}
            <section className="content">
            <div className="container-fluid">
                <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-6">

                    <div className="card card-info">
                    <div className="card-header">
                        <h3 className="card-title">Add Here {checked}</h3>
                    </div>
                    <form onSubmit={onSubmitSection} className="form-horizontal">
                        <div className="card-body">
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Select Course:</label>
                            <div className="col-sm-8">
                            <select className="form-control select2" name='course' onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent}>
                            <option value="">--Select--</option>
                            {data.map((item,key)=>
                                { return(
                                    <option  style={{textTransform:"capitalize"}}  value={item.id} key={key}>{key+1}. {item.title}</option>
                            )})}
                            </select>
                            {errors.course ? <label className="error"> {errors.course ? errors.course : ""} </label> : ""}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Section Name:</label>
                            <div className="col-sm-8">
                            <input className="form-control" id="sec-name" name='title' onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.title} placeholder="Section Name"/>
                            {errors.title ? <label className="error"> {errors.title ? errors.title : ""} </label> : ""}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="description" className="col-sm-4 col-form-label">Description:</label>
                            <div className="col-sm-8">
                                <textarea className="form-control" id="description" name='description' onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.description} placeholder="Description" rows="4"></textarea>
                                {errors.description ? <label className="error"> {errors.description ? errors.description : ""} </label> : ""}
                            </div>
                        </div>
                        <div className="form-group row align-items-center">
                            <label className="col-sm-4 col-form-label" htmlFor="customSwitch3">Status:</label>
                            <div className="col-sm-8">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" name='status_id' onChange={changestatus} value={fields.status_id} id="customSwitch3" />
                                <label className="custom-control-label" htmlFor="customSwitch3"></label>
                                {errors.status_id ? <label className="error"> {errors.status_id ? errors.status_id : ""} </label> : ""}
                            </div>
                            </div>
                        </div>

                        <div className="form-group row align-items-center">
                            <label htmlFor="inputPassword3" className="col-sm-4 col-form-label">is mandatory to move next:</label>
                            <div className="custom-control col-md-4 custom-radio">
                            <input className="custom-control-input" type="radio" id="customRadio1" onChange={changestatusMandate} value={fields.mandatory} name="mandatory"/>
                            <label htmlFor="customRadio1" className="custom-control-label">yes</label>
                            </div>
                            <div className="custom-control col-md-4 custom-radio">
                            <input className="custom-control-input" type="radio" id="customRadio2" onChange={changestatusMandate} value={fields.mandatory} name="mandatory" defaultChecked/>
                            <label htmlFor="customRadio2" className="custom-control-label">No</label>
                            </div>
                            {errors.mandatory ? <label className="error"> {errors.mandatory ? errors.mandatory : ""} </label> : ""}
                        </div>

                        </div>
                        
                        <div className="card-footer">
                        <input type="submit" className="btn btn-info" name='Submit'/>
                        {/* <button type="submit" className="btn btn-default float-right">Cancel</button> */}
                        </div>

                    </form>
                    </div>
                </div>
                
                </div>
            </div>
            </section>
     
        </>
    );
}

export default AddSections;
