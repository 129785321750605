import React from 'react';
import './config';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';
import Contactus from './Pages/Website/Contactus';
import Dashboard from './Pages/Dashboard/Dashboard';
import Aboutus from './Pages/Website/Aboutus';
import Login from './Pages/Website/Login';
import Register from './Pages/Website/Register';
import Jobs from './Pages/Website/Jobs';
import Services from './Pages/Website/Services';
import VerifyCertificate from './Pages/Website/VerifyCertificate';
import Courses from './Pages/Website/Courses';
import AddCourses from './Pages/Dashboard/AddCourses';
import AllUsers from './Pages/Dashboard/Allusers';
import AddSections from './Pages/Dashboard/AddSections';
import AllCourses from './Pages/Dashboard/AllCourses';
import DashboardLayout from './Layouts/Dashboard/DashboardLayout';
import Layout from './Layouts/Website/Layout';
import DashboardPageNotFound from './Pages/Dashboard/DashboardPageNotFound';
import PageNotFound from './Pages/Website/PageNotFound';
import AllSections from './Pages/Dashboard/AllSections';
import AddLessions from './Pages/Dashboard/AddLessions';
import AddTest from './Pages/Dashboard/AddTest';
import AllLessions from './Pages/Dashboard/AllLessions';
import AllTests from './Pages/Dashboard/AllTests';



const root = ReactDOM.createRoot(document.getElementById('root'));
const userdata = JSON.parse(sessionStorage.getItem('LoggedInUser'));
// console.log(userdata)
root.render(
  
  <React.StrictMode>
      <BrowserRouter >
      {userdata == null?
          <Layout>
            <Routes  >
                <Route exact path="/" element={<App />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/contact-us" element={<Contactus />} />
                <Route path="/courses" element={<Courses />} />
                <Route path="/courses" element={<Courses />} />
                <Route path="/about-us" element={<Aboutus />} />
                <Route path="/search-jobs" element={<Jobs />} />
                <Route path="/services" element={<Services />} />
                <Route path="/verify-certificates" element={<VerifyCertificate />} />
                <Route path="*" element={<PageNotFound />}/>
              </Routes>
          </Layout>
          :''}
        {userdata == null?'':
        (userdata.role === 'Admin'?
          <DashboardLayout>
              <Routes >
              <Route path="/all-users" element={<AllUsers/>} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/all-courses" element={<AllCourses/>} />
              <Route path="/add-courses" element={<AddCourses/>} />
              <Route path="/add-sections" element={<AddSections/>} />
              <Route path="/add-lesson" element={<AddLessions/>} />
              <Route path="/all-sections" element={<AllSections/>} />
              <Route path="/all-lessons" element={<AllLessions/>} />
              <Route path="/add-test" element={<AddTest/>} />
              <Route path="/all-tests" element={<AllTests/>} />
              <Route path="*" element={<DashboardPageNotFound />}/>
            </Routes>
        </DashboardLayout>
        :
        <DashboardLayout>
            <Routes >
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="*" element={<DashboardPageNotFound />}/>
          </Routes>
      </DashboardLayout>
        )
      }
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
