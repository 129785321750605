import React from 'react';
import { Link } from 'react-router-dom';
const Courses = () => {
    return (
        <>
        
        <div className="container-fluid bg-breadcrumb-course">
            <div className="container breadcrumb-center text-center py-5">
                <h3 className="text-white display-3 mb-4">Courses</h3>
                <ol className="breadcrumb justify-content-center mb-0">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item active text-white">Courses</li>
                </ol>    
            </div>
        </div>
        <div className="container-fluid packages py-5">
            <div className="container py-5">
                <div className="packages-section mx-auto text-center mb-5">
                    <h5 className="section-title px-3">Courses</h5>
                    <h1 className="mb-0">Awesome Courses</h1>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="packages-item">
                                <div className="packages-img">
                                    <img src="new assets/img/Payroll.jpg" className="img-fluid w-100 rounded-top" alt="Image"/>
                                </div>
                                <div className="packages-content bg-light">
                                    <div className="p-4 pb-0">
                                        <h5 className="mb-0">Pay Rolls</h5>
                                        <p className="mb-4">Efficient payroll processing for accurate salary calculations and compliance with labor laws, freeing you to focus on your business.</p>
                                    </div>
                                    <div className="row bg-primary rounded-bottom mx-0">
                                        <div className="col-6 text-start px-0">
                                            <a href="#" className="btn-hover btn text-white py-2 px-4">Read More</a>
                                        </div>
                                        <div className="col-6 text-end px-0">
                                            <a href="#" className="btn-hover btn text-white py-2 px-4">Enroll Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="packages-item">
                                <div className="packages-img">
                                    <img src="new assets/img/ACCOUNT.webp" className="img-fluid w-100 rounded-top" alt="Image"/>
                                </div>
                                <div className="packages-content bg-light">
                                    <div className="p-4 pb-0">
                                        <h5 className="mb-0">Income Tax and TDS</h5>                               
                                        <p className="mb-4">Our income tax services ensure accurate filing and maximize deductions, helping you stay compliant while minimizing your tax liabilities.</p>
                                    </div>
                                    <div className="row bg-primary rounded-bottom mx-0">
                                        <div className="col-6 text-start px-0">
                                            <a href="#" className="btn-hover btn text-white py-2 px-4">Read More</a>
                                        </div>
                                        <div className="col-6 text-end px-0">
                                            <a href="#" className="btn-hover btn text-white py-2 px-4">Enroll Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="packages-item">
                                <div className="packages-img">
                                    <img src="new assets/img/GST ACC.png" className="img-fluid w-100 rounded-top" alt="Image"/>
                                </div>
                                <div className="packages-content bg-light">
                                    <div className="p-4 pb-0">
                                        <h5 className="mb-0">Goods and Services Tax (GST)</h5>
                                        <p className="mb-4">Simplify your tax compliance with our GST services, ensuring accurate filings and timely submissions to meet all regulatory requirements.</p>
                                    </div>
                                    <div className="row bg-primary rounded-bottom mx-0">
                                        <div className="col-6 text-start px-0">
                                            <a href="#" className="btn-hover btn text-white py-2 px-4">Read More</a>
                                        </div>
                                        <div className="col-6 text-end px-0">
                                            <a href="#" className="btn-hover btn text-white py-2 px-4">Enroll Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Courses;
