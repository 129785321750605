import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navbar = (props) => {
    const location = useLocation(); // once ready it returns the 'window.location' object
    const [url, setUrl] = useState(null);
    useEffect(() => {
      setUrl(location.pathname);
    }, [location]);
    return (<>
        {/* <!-- Navbar & Hero Start --> */}
        <div className="container-fluid position-relative p-0">
            <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
                <Link to="/" className="navbar-brand p-0">
                    <img src="new assets/img/logo-1.png" alt="Logo"/>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="fa fa-bars"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0">
                        <Link to="/courses" className={"nav-item nav-link"+ (url === "/courses" ?" active" : "")}>Courses</Link>
                        <Link to="/about-us" className={"nav-item nav-link"+ (url === "/about-us" ?" active" : "")}>About</Link>
                        <div className="nav-item dropdown">
                            <Link href="#" className={"nav-link dropdown-toggle"+ (url === "/search-jobs" ?" active" : "")} data-bs-toggle="dropdown">Jobs</Link>
                            <div className="dropdown-menu m-0">
                                <Link to="/search-jobs" className="dropdown-item">Search Accounting Job</Link>
                                <Link to="/search-jobs" className="dropdown-item">Search Work from Home</Link>
                            </div>
                        </div>
                        <div className="nav-item dropdown">
                            <Link href="#" className={"nav-link dropdown-toggle"+ (url === "/verify-certificates" ?" active" : "")} data-bs-toggle="dropdown">Services</Link>
                            <div className="dropdown-menu m-0">
                                <Link to="/verify-certificates" className="dropdown-item">Verify Certificate</Link>
                                <Link to="/verify-certificates" className="dropdown-item">Chartered Accountant</Link>
                            </div>
                        </div>
                        <Link to="/contact-us" className={"nav-item nav-link"+ (url === "/contact-us" ?" active" : "")}>Contact</Link>
                    </div>                   
                </div>
            </nav>

            
        </div>
        
        {/* <!-- Navbar & Hero End --> */}
       
        </>
    );
}

export default Navbar;
