import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const DashboardSidebar = (props) => {
    const location = useLocation(); // once ready it returns the 'window.location' object
    const [url, setUrl] = useState(null);
    useEffect(() => {
      setUrl(location.pathname);
    }, [location]);

    const [usersnvtab, setusersnvtab] = useState('');
    const [coursnvtab, setcoursnvtab] = useState('');
    const [testnvtab, settestnvtab] = useState('');
    
    var userdata = JSON.parse(sessionStorage.getItem('LoggedInUser'));
    function Logout(){
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to logout?",
            icon: "warning",
            dangerMode: true,
          })
          .then(willDelete => {
            if (willDelete) {
                toast.success('Logout Successfully.');
                setTimeout(() => {
                  sessionStorage.clear();
                  document.location = "/";
                    // navigate('/');
                  }, 2000)
            }
          });
    }
    const showuserstab = ()=>{
        if(usersnvtab == ''){
            setusersnvtab('showusernavdiv');
        }else{
            setusersnvtab('');
        }
        
    }
    const showcoursestab = ()=>{
        if(coursnvtab == ''){
            setcoursnvtab('showusernavdiv');
        }else{
            setcoursnvtab('');
        }
    }
    const showTesttab = ()=>{
        if(testnvtab == ''){
            settestnvtab('showusernavdiv');
        }else{
            settestnvtab('');
        }
    }
    
    return (<>
    
    <ToastContainer autoClose={2000} />
    <aside className="main-sidebar sidebar-dark-primary elevation-4 sidebarnavcolor">
    {/* <!-- Brand Logo --> */}
    
        <div className="logo-section">
        <img src="dashboard assets/dist/img/logo 1.png"/>
        </div>
        {/* <!-- Sidebar --> */}
        <div className="sidebar">
        {/* <!-- Sidebar user panel (optional) --> */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
            <img src="dashboard assets/dist/img/dummyuser1.jpeg" className="img-circle elevation-2" alt="User Image"/>
            </div>
            <div className="info">
            <a href="#" className="d-block" style={{textTransform:"capitalize"}}>  {userdata ?userdata.name:''}</a>
            </div>
        </div>

        {/* <!-- SidebarSearch Form --> */}
        <div className="form-inline">
            <div className="input-group" data-widget="sidebar-search">
            <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search"/>
            <div className="input-group-append">
                <button className="btn btn-sidebar">
                <i className="fas fa-search fa-fw"></i>
                </button>
            </div>
            </div>
        </div>

        {/* <!-- Sidebar Menu --> */}
        <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            {/* <!-- Add icons to the links using the .nav-icon class
                with font-awesome or any other icon font library --> */}
            <li className="nav-item menu-open">
                <Link to="/dashboard" className={"nav-link"+ (url === "/dashboard" ?" active" : "")}>
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p>
                    Dashboard
                    {/* <!-- <i className="right fas fa-angle-left"></i> --> */}
                </p>
                </Link>
                
            </li>
            {userdata.role != 'Admin'?'':
            
            <li className="nav-item">
                <a onClick={showuserstab} href="#" className="nav-link">
                <i className="nav-icon fas fa-users"></i>
                <p>
                    Users
                    <i className="fas fa-angle-left right"></i>
                </p>
                </a>
                <ul className={`nav nav-treeview ${usersnvtab}`}>
                    <li className="nav-item">
                        <Link to="/all-users" className={"nav-link"+ (url === "/all-users" ?" active" : "")}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>All Users</p>
                        </Link>
                    </li>
                </ul>
            </li>
            }
            {userdata.role != 'Admin'?'':<>
            <li className="nav-item">
                <a onClick={showcoursestab} href="#" className="nav-link">
                <i className="nav-icon fas fa-book"></i>
                <p>
                    My Courses
                    <i className="fas fa-angle-left right"></i>
                </p>
                </a>
                <ul className={`nav nav-treeview ${coursnvtab}`}>
                    
                    
                    
                    <li className="nav-item">
                        <Link to="/all-courses" className={"nav-link"+ (url === "/all-courses" ?" active" : "")}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>All Courses</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/add-courses" className={"nav-link"+ (url === "/add-courses" ?" active" : "")}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>Add Course</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/all-sections" className={"nav-link"+ (url === "/all-sections" ?" active" : "")}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>All Sections</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/add-sections" className={"nav-link"+ (url === "/add-sections" ?" active" : "")}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>Add Section</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/all-lessons" className={"nav-link"+ (url === "/all-lessons" ?" active" : "")}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>All Lessons</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/add-lesson" className={"nav-link"+ (url === "/add-lesson" ?" active" : "")}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>Add Lesson</p>
                        </Link>
                    </li>
                </ul>
            </li>
            <li className="nav-item">
                <a onClick={showTesttab} href="#" className="nav-link">
                <i className="nav-icon fas fa-book"></i>
                <p>
                    Test
                    <i className="fas fa-angle-left right"></i>
                </p>
                </a>
                <ul className={`nav nav-treeview ${testnvtab}`}>
                    <li className="nav-item">
                        <Link to="/all-tests" className={"nav-link"+ (url === "/all-tests" ?" active" : "")}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>All Test</p>
                        </Link>
                    </li>
                    {userdata.role != 'Admin'?'':
                    <><li className="nav-item">
                        <Link to="/add-test" className={"nav-link"+ (url === "/add-test" ?" active" : "")}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>Add Test</p>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/add-questions" className={"nav-link"+ (url === "/add-questions" ?" active" : "")}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>Add Questions</p>
                        </Link>
                    </li>
                    </>
                    }
                </ul>
            </li>
            </>}
            <li className="nav-item">
                <Link  onClick={Logout} className={"nav-link"+ (url === "/add-lessions" ?" active" : "")}>
                <i className="nav-icon fas fa-sign-out-alt"></i>
                <p>
                    Logout
                </p>
                </Link>
            </li>
            </ul>
        </nav>
        {/* <!-- /.sidebar-menu --> */}
        </div>
        {/* <!-- /.sidebar --> */}
    </aside>
      
        </>
    );
}

export default DashboardSidebar;
