import React from 'react';

const CourseRecords = ({data}) => {
    return (
        <table id="example1" className="table table-bordered table-striped">
        <thead>
            <tr>
            <th>S.No.</th>
            <th>Title</th>
            <th>Description</th>
            <th>Price </th>
            <th>Validity </th>
            <th>Status</th>
            </tr>
        </thead>
        <tbody>
             {data.length>0 ?data.map(function(data,index) {
                 return ( 
                    <tr key={data.id}>
                        <td>{index+1}</td>
                        <td style={{textTransform:"capitalize"}}>{data.title}</td>
                        <td style={{textTransform:"capitalize"}}>{data.description}</td>
                        <td > <i className="fa fa-inr" aria-hidden="true"></i> {data.price}</td>
                        <td ><i className="fa-solid fa-calendar-days"></i> {data.validity} </td>
                        <td>
                            <button type="button" className="btn btn-success btn-sm">Active</button>
                        </td>
                    </tr> 
                ) 
                }): <tr><td colSpan={6} >No records available.</td></tr>} 
            </tbody>
        <tfoot>
            <tr>
                <th>S.No.</th>
                <th>Title</th>
                <th>Description</th>
                <th>Price </th>
                <th>Validity </th>
                <th>Status</th>
            </tr>
        </tfoot>
        </table>
    );
}

export default CourseRecords;
