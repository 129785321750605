import React ,{ useState } from 'react';
import { useFormInputValidation } from "react-form-input-validation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  {useLocation , useNavigate } from "react-router-dom";
import axios from 'axios';
const AddCourses = () => { 
    var userdata = JSON.parse(sessionStorage.getItem('LoggedInUser'));
    const navigate = useNavigate();
  const { usertype   } = false;
  const [startspinner, setstartspinner] = useState('stopspinnercls');
  const [btndisable, setbtndisable] = useState('');
    const [fields, errors, form] = useFormInputValidation({
        title: "",
        description: "",
        price: "",
        validity:"",
        status_id:usertype
      }, {
        title: "required",
        description: "required",
        price: "required|numeric",
        validity:"required|numeric",
        status_id:"required"
      });
    
      const onSubmitCourse = async (event) => {
        fields.status_id=false;
        const isValid = await form.validate(event);
        if (isValid) {
          setstartspinner('startspinnercls') ;
          // console.log(fields, errors);
              axios({
              method: "post",
              url: global.config.ApiUri+"addcourse",
              data: fields,
              headers: {
                "Accept": "application/json",
                 "Authorization": `Bearer ${userdata.access_token}`,
                },
              })
              .then(function (response) {
                  //handle success
                  // console.log(response)
                  switch (response.status) {
                      case 200:
                          toast.success(response.data.data.message);
                          setbtndisable('disablebtn');
                          setstartspinner('stopspinnercls') ;
                          break;
                      default:
                          break;
                  }
              })
              .catch(function (response) {
                  //handle error
                  // console.log(response);
                  switch (response.status) {
                      case 422:
                          toast.error(response.response.data.message);
                          setstartspinner('stopspinnercls') ;
                          break;
                      default:
                          break;
                  }
              });
          }
      }
      if (!toast.isActive(13, "friendRequest")) {
        toast({
          autoClose: 1000,
          toastId: 13                      
      })
      }
    return (
        <>
            {/* <div className="preloader flex-column justify-content-center align-items-center ">
                <img className="animation__shake" src="dashboard assets/dist/img/logo 1.png" alt="AdminLTELogo"  width="250"/>
            </div> */}
            <ToastContainer autoClose={1000} containerId={"friendRequest"} />
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>Add Courses</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="../../index.html">Home</a></li>
                        <li className="breadcrumb-item active">Add Courses</li>
                        </ol>
                    </div>
                    </div>
                </div>
                {/* <!-- /.container-fluid --> */}
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                
                <div className="container-fluid">
                    <div className="row">
                    {/* <!-- left column --> */}
                    <div className="col-md-6">

                        <div className="card card-info">
                        <div className="card-header">
                            <h3 className="card-title">Add Here</h3>
                        </div>
                        <form onSubmit={onSubmitCourse} className="form-horizontal">
                            <div className="card-body">
                            <div className="form-group row">
                                <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Course Name:</label>
                                <div className="col-sm-8">
                                <input className="form-control" id="course-name" name='title'onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.title} placeholder="Course Name"/>
                                {errors.title ? <label className="error"> {errors.title ? errors.title : ""} </label> : ""}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="description" className="col-sm-4 col-form-label">Description:</label>
                                <div className="col-sm-8">
                                    <textarea className="form-control" id="description" name='description'onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.description} placeholder="Description" rows="4"></textarea>
                                    {errors.description ? <label className="error"> {errors.description ? errors.description : ""} </label> : ""}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="inputPassword3" className="col-sm-4 col-form-label">Price:</label>
                                <div className="col-sm-8">
                                <input className="form-control" type='number'  id="price" name='price'onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.price} placeholder="price" />
                                {errors.price ? <label className="error"> {errors.price ? errors.price : ""} </label> : ""}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="inputPassword3" className="col-sm-4 col-form-label">Validity:</label>
                                <div className="col-sm-8">
                                <input className="form-control" type='number' id="validity" name='validity'onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.validity} placeholder="validity"/>
                                {errors.validity ? <label className="error"> {errors.validity ? errors.validity : ""} </label> : ""}
                                </div>
                            </div> 
                            <div className="form-group row align-items-center">
                                <label className="col-sm-4 col-form-label" htmlFor="customSwitch3">Status:</label>
                                <div className="col-sm-8">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" name='status_id' id="customSwitch3" />
                                    
                                    <label className="custom-control-label" htmlFor="customSwitch3"></label>
                                    {errors.status_id ? <label className="error"> {errors.status_id ? errors.status_id : ""} </label> : ""}
                                </div>
                                </div>
                            </div>     
                            </div>

                            <div className="card-footer">
                            <input type="submit" className="btn btn-info" name='Submit'/>
                            {/* <button type="submit" className="btn btn-default float-right">Cancel</button> */}
                            </div>

                        </form>
                        </div>
                    </div>
                    {/* <!--/.col (left) --> */}
                    {/* <!-- right column --> */}
                    
                    {/* <!--/.col (righ/t) --> */}
                    </div>
                    {/* <!-- /.row --> */}
                </div>
                {/* <!-- /.container-fluid --> */}
                </section>
                {/* <!-- /.content --> */}
           
        </>
    );
}

export default AddCourses;
