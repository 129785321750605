import React ,{useEffect} from 'react'; 
import  { useNavigate} from "react-router-dom";
import { Link } from 'react-router-dom';
const Aboutus = () => {
    const navigate = useNavigate();
    // const storedValue = sessionStorage.getItem('LoggedInUser');
   
    return (
        <>
            {/* <!-- Header Start --> */}
        <div className="container-fluid bg-breadcrumb-about">
            <div className="container breadcrumb-center text-center py-5">
                <h3 className="text-white display-3 mb-4">About US</h3>
                <ol className="breadcrumb justify-content-center mb-0">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item active text-white">About</li>
                </ol>    
            </div>
        </div>
        {/* <!-- Header End --> */}
        {/* <!-- About Start --> */}
        <div className="container-fluid about py-5">
            <div className="container py-5">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-5">
                        <div className="h-100 about-hero-img">
                            <img src="new assets/img/accountsabout.jpg" className="img-fluid w-100 h-100" alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-7 about-back-img">
                        <h5 className="section-about-title pe-3">About Us</h5>
                        <h1 className="mb-4">Welcome to <span className="text-primary">Accountants Club</span></h1>
                        <p className="mb-4">Welcome to the Accountants Club, where aspiring financial professionals connect, collaborate, and grow. Our mission is to foster a supportive community that enhances knowledge and skills in accounting and finance.</p>
                        <p className="mb-4">Through workshops, networking events, and mentorship opportunities, we empower our members to excel in their careers and stay updated on industry trends. Join us to elevate your accounting journey!</p>
                        <div className="row gy-2 gx-4 mb-4">
                            <div className="col-sm-6">
                                <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Tax Preparation Assistance</p>
                            </div>
                            <div className="col-sm-6">
                                <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Financial Statement Analysis</p>
                            </div>
                            <div className="col-sm-6">
                                <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Bookkeeping Services</p>
                            </div>
                            <div className="col-sm-6">
                                <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Budgeting and Forecasting</p>
                            </div>
                            <div className="col-sm-6">
                                <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Audit Support</p>
                            </div>
                            <div className="col-sm-6">
                                <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Accounting Software Training</p>
                            </div>
                        </div>
                        <a className="btn btn-primary rounded-pill py-3 px-5 mt-2" href="">Read More</a>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- About End --> */}
             
        </>
    );
}

export default Aboutus;
