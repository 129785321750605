import React from 'react';
import Header from './DashboardHeader'
import Sidebar from './DashboardSidebar';
import Footer from './DashboardFooter';

const DashboardLayout = ({children}) => {
    return (
        <>
            <Header/>
            <Sidebar/>
            <div className="content-wrapper">
            {children}
            </div>
            <Footer />
        </>
        
    );
}

export default DashboardLayout;
