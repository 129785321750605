import React from 'react';

const Footer = () => {
    return (
        <>
        {/* <!-- Footer Start --> */}
        <div className="container-fluid footer py-5">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-md-6 col-lg-6 col-xl-4">
                        <div className="footer-item d-flex flex-column">
                            <h4 className="mb-4 text-white">Get In Touch</h4>
                            <a href="#"><i className="fas fa-home me-2"></i> 123 Street, New York, USA</a>
                            <a href="#"><i className="fas fa-envelope me-2"></i> info@example.com</a>
                            <a href="#"><i className="fas fa-phone me-2"></i> +012 345 67890</a>
                            <a href="#" className="mb-3"><i className="fas fa-print me-2"></i> +012 345 67890</a>
                            <div className="d-flex align-items-center">
                                <i className="fas fa-share fa-2x text-white me-2"></i>
                                <a className="btn-square btn btn-primary rounded-circle mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                <a className="btn-square btn btn-primary rounded-circle mx-1" href=""><i className="fab fa-twitter"></i></a>
                                <a className="btn-square btn btn-primary rounded-circle mx-1" href=""><i className="fab fa-instagram"></i></a>
                                <a className="btn-square btn btn-primary rounded-circle mx-1" href=""><i className="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-4">
                        <div className="footer-item d-flex flex-column">
                            <h4 className="mb-4 text-white">Company</h4>
                            <a href="#"><i className="fas fa-angle-right me-2"></i> Courses</a>
                            <a href="#"><i className="fas fa-angle-right me-2"></i> About</a>
                            <a href="#"><i className="fas fa-angle-right me-2"></i> Jobs</a>
                            <a href="#"><i className="fas fa-angle-right me-2"></i> Services</a>
                            <a href="#"><i className="fas fa-angle-right me-2"></i> Contact</a>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-4">
                        <div className="footer-item d-flex flex-column">
                            <h4 className="mb-4 text-white">Support</h4>
                            <a href="#"><i className="fas fa-angle-right me-2"></i> Contact</a>
                            <a href="#"><i className="fas fa-angle-right me-2"></i> Legal Notice</a>
                            <a href="#"><i className="fas fa-angle-right me-2"></i> Privacy Policy</a>
                            <a href="#"><i className="fas fa-angle-right me-2"></i> Terms and Conditions</a>
                            {/* <a href="#"><i className="fas fa-angle-right me-2"></i> Sitemap</a> */}
                            <a href="#"><i className="fas fa-angle-right me-2"></i> Cookie policy</a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        {/* <!-- Footer End --> */}
        
        {/* <!-- Copyright Start --> */}
        <div className="container-fluid copyright text-body py-4">
            <div className="container">
                <div className="row g-4 align-items-center">
                    <div className="col-md-6 text-center text-md-end mb-md-0">
                        <i className="fas fa-copyright me-2"></i><a className="text-white" href="#">Your Site Name</a>, All right reserved.
                    </div>
                    <div className="col-md-6 text-center text-md-start">
                        
                        
                        Designed & Developed By <a className="text-white" href="https://abc.com">ABC</a>
                        
        
                    
                    </div>
                </div>
            </div>
        </div>
        {/* // <!-- Copyright End --> */}
        <a href="#" className="btn btn-primary btn-primary-outline-0 btn-md-square back-to-top"><i className="fa fa-arrow-up"></i></a>   
        </>
    );
}

export default Footer;
