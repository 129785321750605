import React from 'react';

const SectionRecords = ({data}) => {
    return (
        <table id="example1" className="table table-bordered table-striped">
        <thead>
            <tr>
            <th>S.No.</th>
            <th>Title</th>
            <th>Description</th>
            <th>Course Under </th>
            <th>Move to Next page status Id </th>
            <th>Status</th>
            </tr>
        </thead>
        <tbody>
                {data.length>0 ?data.map(function(data,index) {
                 return ( 
                    <tr key={data.id}>
                        <td>{index+1}</td>
                        <td style={{textTransform:"capitalize"}}>{data.title}</td>
                        <td style={{textTransform:"capitalize"}}>{data.description}</td>
                        <td style={{textTransform:"capitalize"}}>{data.courseTitle}</td>
                        <td >{data.mandatory ==1?<p>Yes</p>:<p>No</p>} </td>
                        <td>
                            <button type="button" className="btn btn-success btn-sm">Active</button>
                        </td>
                    </tr> 
                ) 
                }): <tr><td colSpan={6} >No records available.</td></tr>} 
            </tbody>
        <tfoot>
            <tr>
                <th>S.No.</th>
                <th>Title</th>
                <th>Description</th>
                <th>Course Under </th>
                <th>Move to Next page Status Id </th>
                <th>Status</th>
            </tr>
        </tfoot>
        </table>
    );
}

export default SectionRecords;
