import React, { useState } from 'react'; 
// import AuthenticationStatus from '../../Middleware/AuthMiddleware';
import { useFormInputValidation } from "react-form-input-validation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  {useLocation} from "react-router-dom";
import axios from 'axios';
const Login = () => {
    // AuthenticationStatus();
    const location = useLocation();
    const [startspinner, setstartspinner] = useState('stopspinnercls');
    const [btndisable, setbtndisable] = useState('');
    const [checked, setChecked] = useState(false);
    
    const { usertype   } = location.state ? location.state : "null";
    
        const [fields, errors, form] = useFormInputValidation({
            email: "",
            password: "",
            role:"",
            rememberme:false
        }, {
            email: "required|email",
            password: "required|numeric|digits_between:7,12",
            role:"required",
            rememberme:"required"
        });
        const handleChange = ()=>{
            setChecked(!checked); 
            {checked ? (fields.rememberme = false) : (fields.rememberme = true)}
        }
        const onSubmitLogin = async (event) => {
            if(usertype == null){
                fields.role='Admin';
            }else{
                fields.role=usertype;
            }
            
            const isValid = await form.validate(event);
            if (isValid) {
                setstartspinner('startspinnercls') ;
            // console.log(fields, errors);
                axios({
                method: "post",
                url: global.config.ApiUri+"login",
                data: fields,
                headers: {"Content-Type": "multipart/form-data" },
                })
                .then(function (response) {
                    setstartspinner('stopspinnercls') ;
                    
                    //handle success
                    // console.log(response)
                    switch (response.status) {
                        case 200:
                            setbtndisable('disablebtn');
                            toast.success(response.data.data.message);
                            sessionStorage.setItem('LoggedInUser', JSON.stringify(response.data.data));
                            setTimeout(() => {
                                document.location = "/dashboard";
                                // navigate('/dashboard');
                              }, 2000)
                            
                            break;
                        default:
                            break;
                    }
                })
                .catch(function (response) {
                    setstartspinner('stopspinnercls') ;
                    //handle error
                    // console.log(response);
                    switch (response.status) {
                        case 401:
                            toast.error(response.response.data.message);
                            break;
                        case 422:
                            toast.error(response.response.data.message);
                            break;
                        default:
                            break;
                    }
                });
            }
        }
        if (!toast.isActive(13, "friendRequest")) {
            toast({
                autoClose: 1000,
                toastId: 13                      
            })
        }
    return (
        <>
         <div id="spinner" className={` show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center ${startspinner} `}>
            <div className="spinner-border text-primary"  role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
        

<ToastContainer autoClose={1000} containerId={"friendRequest"} />
            {/* <!-- Login FORM STARTS--> */}
            <div className="login-form form-image">
                <div className="wrapper">
                    <div className="title">{usertype} Login </div>
                    <form onSubmit={onSubmitLogin}>
                    <div className="field">
                        <input type="email" name="email" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.email}  />
                        <label>Email Address</label>
                        <label className="error"> {errors.email ? errors.email : ""} </label>
                        </div>
                        <div className="field">
                        <input type="password" name='password' onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.password}  />
                        <label>Password</label>
                        <label className="error"> {errors.password ? errors.password : ""} </label>
                        </div>
                        <div className="remember-forgot my-4 d-flex justify-content-center">
                            <div className="remember-me me-5">
                            <input id="remember-me" type="checkbox" name="rememberme" onChange={handleChange}/>
                            <label htmlFor="remember-me">Remember me</label>
                            </div>
                            <a href="#">Forgot Password?</a>
                        </div>
                        <div className="field">
                        <input type="submit" value="Login" className={btndisable}/>
                        </div>
                    </form>
                </div>
            </div>
            {/* <!-- Login FORM ENDS--> */}
            
        </>
    );
}

export default Login;
