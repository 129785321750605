import React ,{ useState } from 'react';
import { useFormInputValidation } from "react-form-input-validation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  {useLocation , useNavigate } from "react-router-dom";
import axios from 'axios';
const Register = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { usertype   } = location.state ? location.state : "null";
  const [startspinner, setstartspinner] = useState('stopspinnercls');
  const [btndisable, setbtndisable] = useState('');
    const [fields, errors, form] = useFormInputValidation({
        name: "",
        email: "",
        password: "",
        role:""
      }, {
        name: "required",
        email: "required|email",
        password: "required|numeric|digits_between:7,12",
        role:"required"
      });
    
      const onSubmitRegister = async (event) => {
        fields.role=usertype;
        const isValid = await form.validate(event);
        if (isValid) {
          setstartspinner('startspinnercls') ;
          // console.log(fields, errors);
              axios({
              method: "post",
              url: global.config.ApiUri+"register",
              data: fields,
              headers: {"Content-Type": "multipart/form-data" },
              })
              .then(function (response) {
                  //handle success
                  // console.log(response)
                  switch (response.status) {
                      case 200:
                          toast.success(response.data.data.message);
                          setbtndisable('disablebtn');
                          setstartspinner('stopspinnercls') ;
                          sessionStorage.setItem('LoggedInUser', JSON.stringify(response.data.data));
                          setTimeout(() => {
                            document.location = "/dashboard";
                            }, 2000)
                            
                          break;
                      default:
                          break;
                  }
              })
              .catch(function (response) {
                  //handle error
                  // console.log(response);
                  switch (response.status) {
                      case 422:
                          toast.error(response.response.data.message);
                          setstartspinner('stopspinnercls') ;
                          break;
                      default:
                          break;
                  }
              });
          }
      }
      if (!toast.isActive(13, "friendRequest")) {
        toast({
          autoClose: 1000,
          toastId: 13                      
      })
      }
    return (
      <>
         <div id="spinner" className={` show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center ${startspinner} `}>
            <div className="spinner-border text-primary"  role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
        <ToastContainer autoClose={1000} containerId={"friendRequest"} />
        
        <div className="login-form">
          <div className="wrapper">
              <div className="title">
               {usertype} Registration
              </div>
              <form id="registerform" onSubmit={onSubmitRegister}>
                  <div className="field">
                    <input id="name" type="text" name="name" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.name} />
                    <label>Name</label>
                    <label className="error"> {errors.name ? errors.name : ""} </label>
                  </div>
                  <div className="field">
                    <input type="email" name="email" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.email} />
                    <label>Email Address</label>
                    <label className="error"> {errors.email ? errors.email : ""} </label>
                  </div>
                  <div className="field">
                    <input type="number" name="password" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.password} />
                    <label>Password</label>
                    <label className="error"> {errors.password ? errors.password : ""} </label>
                  </div>
                  <div className="field">
                    <input type="submit" value="Register"  className={btndisable}/>
                  </div>
                  
              </form>
            </div>
        </div>
        
        </>
    );
}

export default Register;
