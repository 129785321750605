import React ,{ useState } from 'react';
import { useFormInputValidation } from "react-form-input-validation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import PreLoader from '../../Components/Preloader';
const AddLessions = () => {
    const sessiontoken = JSON.parse(sessionStorage.getItem('LoggedInUser'));
    const [checked, setChecked] = useState(false);
    const [mandatechecked, setmandatechecked] = useState(false);
    const [data, setData] = useState([]);
    const [sectionData, setSectionData] = useState([]);
    const [videofile, setVideoFile] = useState()
    const [studymaterial, setStudymaterial] = useState()
    const [startspinner, setstartspinner] = useState('stopspinnercls');
    const [spinnerhsD, setspinnerhsD] = useState('stopdashloader');
    
    var dateTime = require('get-date');
    React.useEffect(() => {
        
        axios({
            method: "post",
            url: global.config.ApiUri+"getcourses",
            data: '',
            headers: {'Content-Type': 'application/json','Authorization': `Bearer ${sessiontoken.access_token}`},
            })
            .then(function (response) {
                // console.log(response.data.data)
                switch (response.status) {
                    case 200:
                        setData(response.data.data);
                        break;
                    default:
                        break;
                }
            })
            .catch(function (response) {
            });
      }, []);
      const getsection=()=>{
        // console.log(fields.course);
        axios({
            method: "post",
            url: global.config.ApiUri+"getsections",
            data: {'id':fields.course},
            headers: {'Content-Type': 'application/json','Authorization': `Bearer ${sessiontoken.access_token}`},
            })
            .then(function (response) {
                // console.log(response.data.data)
                switch (response.status) {
                    case 200:
                        setSectionData(response.data.data);
                        break;
                    default:
                        break;
                }
            })
            .catch(function (response) {
            });
      }
      
      const [fields, errors, form] = useFormInputValidation({
        course: "",
        section:"",
        lession_no:"",
        lession_title: "",
        lession_description: "",
        upload_release:"",
        mandatory:"",
        upload_video:"",
        video_embed:"",
        video_description:"",
        status_id:checked,
      }, {
        course: "required",
        section:"required",
        lession_no:"required|numeric",
        lession_title: "required",
        lession_description: "required",
        upload_release:"required|numeric",
        mandatory: "required",
        upload_video:"required",
        status_id: "required",
        video_embed:"required",
        video_description:"required"
      });
      function uploadVideo(event){
        setVideoFile(event.target.files[0])
        fields.upload_video=event.target.files[0];
      }
      function uploadpdf(event){
        setStudymaterial(event.target.files[0])
        // fields.study_material=event.target.files[0];
      }
      const onSubmitLession = async (event) => {
        
        fields.video_embed=".";
        // fields.video_description=".";
        fields.status_id=checked;
        fields.mandatory=mandatechecked;
        console.log(fields)
        const isValid = await form.validate(event);
        if (isValid) {
            setstartspinner('startspinnercls') ;
            setspinnerhsD('showusernavdiv') ;
            // console.log(fields)
            const formData = new FormData();
            // formData.append('_method', 'POST');
            formData.append('course', fields.course);
            formData.append('section', fields.section);
            formData.append('lession_no', fields.lession_no);
            formData.append('lession_title', fields.lession_title);
            formData.append('lession_description', fields.lession_description);
            formData.append('video_description', fields.video_description);
            formData.append('video_embed', fields.video_embed);
            formData.append('upload_video', videofile);
            formData.append('study_material', studymaterial);
            formData.append('upload_release', fields.upload_release);
            formData.append('mandatory', fields.mandatory);
            formData.append('status_id', fields.status_id);
            
            axios({
                method: "post",
                url: global.config.ApiUri+"addlession",
                data: formData,
                headers: {
                    "Accept": "application/json",
                  'Content-Type': 'multipart/form-data',
                   "Authorization": `Bearer ${sessiontoken.access_token}`,
                  },
                })
                .then(function (response) {
                    setstartspinner('stopspinnercls') ;
                    setspinnerhsD('stopdashloader') ;
                    
                    //handle success
                    // console.log(response)
                    switch (response.status) {
                        case 200:
                            toast.success(response.data.data.message);
                            
                            break;
                        default:
                            break;
                    }
                })
                .catch(function (response) {
                    setstartspinner('stopspinnercls') ;
                    setspinnerhsD('stopdashloader') ;
                    //handle error
                    // console.log(response);
                    switch (response.status) {
                        case 422:
                            toast.error(response.response.data.message);
                           
                            break;
                        default:
                            break;
                    }
                });
            }
    }
    const changestatus=()=>{
        setChecked(!checked); 
            {checked ? (fields.status_id = false) : (fields.status_id = true)}
      }
      const changestatusMandate=()=>{
        setmandatechecked(!checked); 
            {mandatechecked ? (fields.mandatory = false) : (fields.mandatory = true)}
      }
    return (
        <>
          <div className={`preloader flex-column justify-content-center align-items-center ${startspinner}`}>
                <img className={`animation__shake ${spinnerhsD}`} src="dashboard assets/dist/img/logo 1.png" alt="AdminLTELogo"  width="250"/>
            </div>
            <ToastContainer autoClose={1000} containerId={"friendRequest"} />
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>Add Lession</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="../../index.html">Home</a></li>
                        <li className="breadcrumb-item active">Add Lession</li>
                        </ol>
                    </div>
                    </div>
                </div>
                {/* <!-- /.container-fluid --> */}
                </section>
                <PreLoader/>
                {/* <!-- Main content --> */}
                <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        {/* <!-- left column --> */}
                        <div className="col-md-6">
                            <div className="card card-info">
                                <div className="card-header">
                                    <h3 className="card-title">Add Here</h3>
                                </div>
                                <form onSubmit={onSubmitLession} className="form-horizontal" >
                                    <div className="card-body">
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Select Course:</label>
                                        <div className="col-sm-8">
                                        <select className="form-control select2" name='course' onBlur={form.handleBlurEvent}  onChange={form.handleChangeEvent} onClick={getsection} >
                                        <option value="">--Select--</option>
                                        {data.map((item,key)=>
                                            { return(
                                                <option  style={{textTransform:"capitalize"}}  value={item.id} key={key}>{key+1}. {item.title}</option>
                                        )})}
                                        </select>
                                        {errors.course ? <label className="error"> {errors.course ? errors.course : ""} </label> : ""}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Select Section:</label>
                                        <div className="col-sm-8">
                                        <select className="form-control" name='section' onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent}>
                                        <option value="">--Select--</option>
                                            {sectionData.map((item,key)=>
                                                { return(
                                                    <option  style={{textTransform:"capitalize"}}  value={item.id} key={key}>{key+1}. {item.title}</option>
                                            )})}
                                        </select>
                                        {errors.section ? <label className="error"> {errors.section ? errors.section : ""} </label> : ""}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="lesson" className="col-sm-4 col-form-label">Lesson Number:</label>
                                        <div className="col-sm-8">
                                        <input type='number' className="form-control" id="lesson" name='lession_no' placeholder="Lesson Number" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.lession_no}/>
                                        {errors.lession_no ? <label className="error"> {errors.lession_no ? errors.lession_no : ""} </label> : ""}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="lesson-title" className="col-sm-4 col-form-label">Lesson Title:</label>
                                        <div className="col-sm-8">
                                        <input className="form-control" id="lesson-title" name='lession_title' placeholder="Lesson Title" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.lession_title}/>
                                        {errors.lession_title ? <label className="error"> {errors.lession_title ? errors.lession_title : ""} </label> : ""}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="lesson-description" className="col-sm-4 col-form-label">Lesson Description:</label>
                                        <div className="col-sm-8">
                                        <input className="form-control" id="lesson-description" name='lession_description' placeholder="Lesson Description" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.lession_description}/>
                                        {errors.lession_description ? <label className="error"> {errors.lession_description ? errors.lession_description : ""} </label> : ""}
                                        </div>
                                    </div>
                                    
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Upload Video:</label>
                                        <div className="col-sm-8">
                                            <input type="file" className="custom-file-input1" name='upload_video' id="exampleInputFile1" onChange={uploadVideo} onBlur={form.handleBlurEvent}   required/>
                                            {errors.upload_video ? <label className="error"> {errors.upload_video ? errors.upload_video : ""} </label> : ""}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="v-description" className="col-sm-4 col-form-label">Video Description:</label>
                                        <div className="col-sm-8">
                                        <input className="form-control" id="v-description" name='video_description' placeholder="Video Description" onBlur={form.handleBlurEvent}  onChange={form.handleChangeEvent}  value={fields.video_description}/>
                                        {errors.video_description ? <label className="error"> {errors.video_description ? errors.video_description : ""} </label> : ""}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="v-upload_release" className="col-sm-4 col-form-label">Upload Release:</label>
                                        <div className="col-sm-8">
                                        <input type="number" className="form-control" id="v-upload_release" name='upload_release' placeholder="Upload Release" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.upload_release}/>
                                        {errors.upload_release ? <label className="error"> {errors.upload_release ? errors.upload_release : ""} </label> : ""}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="v-embed-code" className="col-sm-4 col-form-label">Video Embed Code:</label>
                                        <div className="col-sm-8">
                                        <input className="form-control" id="v-embed-code" name='video_embed' placeholder="Video Embed Code" onBlur={form.handleBlurEvent}  onChange={form.handleChangeEvent}   value={fields.video_embed} />
                                        {errors.video_embed ? <label className="error"> {errors.video_embed ? errors.video_embed : ""} </label> : ""}
                                        </div>
                                    </div>
                                    
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Study Material:</label>
                                        <div className="col-sm-8">
                                        <input type="file" className="custom-file-input1" name='study_material' id="exampleInputFile5" onChange={uploadpdf} onBlur={form.handleBlurEvent} />
                                        {errors.study_material ? <label className="error"> {errors.study_material ? errors.study_material : ""} </label> : ""}
                                        </div>
                                    </div>
                                    
                                    
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Upload Date:</label>
                                        <div className="input-group date col-sm-8" id="reservationdate" data-target-input="nearest">
                                        {dateTime()}
                                        {/* <div className="input-group-append" data-target="#reservationdate" data-toggle="datetimepicker">
                                            <div className="input-group-text"><i className="fa fa-calendar"></i></div>
                                        </div> */}
                                        </div>
                                    </div>
                                    <div className="form-group row align-items-center">
                                        <label className="col-sm-4 col-form-label" htmlFor="customSwitch3">Status:</label>
                                        <div className="col-sm-8">
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" className="custom-control-input" name='status_id' onChange={changestatus} value={fields.status_id} id="customSwitch3"/>
                                                <label className="custom-control-label" htmlFor="customSwitch3"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row align-items-center">
                                        <label className="col-sm-4 col-form-label">Is Mandatory to Move Next:</label>
                                        <div className="col-sm-4 custom-control custom-radio">
                                            <input className="custom-control-input" type="radio" id="customRadio1" name="mandatory" onChange={changestatusMandate} value={fields.mandatory}/>
                                            <label htmlFor="customRadio1" className="custom-control-label">Yes</label>
                                        </div>
                                        <div className="col-sm-4 custom-control custom-radio">
                                            <input className="custom-control-input" type="radio" id="customRadio2" name="mandatory" onChange={changestatusMandate} value={fields.mandatory} defaultChecked/>
                                            <label htmlFor="customRadio2" className="custom-control-label">No</label>
                                        </div>
                                    </div>
                                    </div>          
                                    <div className="card-footer">
                                    <input type="submit" className="btn btn-info" name='Submit'/>
                                    {/* <button type="button" className="btn btn-default float-right">Cancel</button> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
        
        </>
    );
}

export default AddLessions;
