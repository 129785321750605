import React from 'react';
const Jobs = () => {
    return (
        <>
           {/* <!-- Header Start --> */}
        <div className="container-fluid bg-breadcrumb-jobs">
            <div className="container breadcrumb-center text-center py-5">
                <h3 className="text-white display-3 mb-4">Search Jobs</h3>
                <ol className="breadcrumb justify-content-center mb-0">
                    <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                    <li className="breadcrumb-item active text-white">Jobs</li>
                </ol>    
            </div>
        </div>
        {/* <!-- Header End --> */}


        {/* <!-- search bar --> */}
        <div className="container">
            <div className="row mt-3 height d-flex align-items-center">
              <div className="col-md-8 job-search">
                <div className="search">
                  <i className="fa fa-search"></i>
                  <input type="text" className="form-control" placeholder="Search Your Job"/>
                  <button className="btn btn-primary">Search</button>
                </div>     
              </div>         
            </div>
        </div>
        {/* <!-- search bar ends --> */}


        {/* <!-- jobs section starts --> */}
        <div className="jobs-section">
            <div className="container">
                <div className="row job-row">
                    <div className="col-12 col-md-6 jobs-column">
                        <div className="card jobs-card">
                            <div className="card-body">
                                <h4 className="card-title">Senior Accountant</h4>
                                <h6>XYZ Financial Services</h6>
                                <div className="employee-info">
                                    <p><i className="fa-solid fa-bag-shopping"></i>&nbsp;&nbsp;&nbsp;<span>4-6 Yrs</span></p>
                                    <p><i className="fa-solid fa-rupee-sign"></i>&nbsp;&nbsp;&nbsp;<span>Not Disclosed</span></p>
                                    <p className="no-border"><i className="fa-solid fa-location-crosshairs"></i>&nbsp;&nbsp;&nbsp;Pune, India</p>
                                </div>
                                <p className="card-text mt-3">
                                    <i className="fa-regular fa-clipboard"></i>&nbsp;&nbsp;We are seeking a Senior Accountant to manage financial reporting, tax compliance, and internal audits.
                                </p>
                                <div className="full-description" >
                                    <p className="card-text">
                                        The ideal candidate has 5+ years of experience in general ledger accounting and is proficient in financial analysis tools like Excel and QuickBooks.
                                    </p>
                                    <div className="job-keys">
                                        <h5>Key Responsibilities:</h5>
                                        <ul>
                                            <li>Prepare and present accurate financial statements, ensuring compliance with accounting standards.</li>
                                            <li>Manage tax filings, ensuring adherence to local regulations and timely submission of returns.</li>
                                            <li>Liaise with external auditors during audits, providing necessary documentation and explanations.</li>
                                            <li>Assist in preparing annual budgets and financial forecasts to guide business decisions.</li>
                                            <li>Oversee day-to-day accounting operations, ensuring accuracy and efficiency in financial transactions.</li>
                                        </ul>
                                    </div>
                                    <div className="job-requirement">
                                        <h5>Requirements:</h5>
                                        <ul>
                                            <li>Bachelor’s degree in Accounting, Finance, or a related field; professional certification (CA/CPA) is a plus.</li>
                                            <li>4-6 years of relevant experience in accounting, with a strong focus on financial reporting and tax compliance.</li>
                                            <li>Proficiency in accounting software (e.g., Tally, SAP) and advanced Excel skills.</li>
                                            <li>Strong analytical and problem-solving abilities to interpret financial data and trends.</li>
                                            <li>Excellent verbal and written communication skills to interact with internal and external stakeholders effectively.</li>
                                        </ul>
                                    </div>
                                </div>
                                <a className="btn btn-primary rounded-pill py-3 px-5 mt-2" href="javascript:void(0);" onclick="toggleDescription(this)">Read More</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 jobs-column">
                        <div className="card jobs-card">
                            <div className="card-body">
                                <h4 className="card-title">Accounts Payable Specialist</h4>
                                <h6>ABC Corporation</h6>
                                <div className="employee-info">
                                    <p><i className="fa-solid fa-bag-shopping"></i>&nbsp;&nbsp;&nbsp;<span>2-3 Yrs</span></p>
                                    <p><i className="fa-solid fa-rupee-sign"></i>&nbsp;&nbsp;&nbsp;<span>Not Disclosed</span></p>
                                    <p className="no-border"><i className="fa-solid fa-location-crosshairs"></i>&nbsp;&nbsp;&nbsp;Gurgaon, India</p>
                                </div>
                                <p className="card-text mt-3">
                                    <i className="fa-regular fa-clipboard"></i>&nbsp;&nbsp;We have immediate openings for Accounts Payable Specialists. Looking for candidates with experience in invoice processing and vendor reconciliation.
                                </p>
                                <div className="full-description">
                                    <p className="card-text">
                                        The ideal candidates should have strong attention to detail and excellent organizational skills. Proficiency in accounting software and the ability to manage multiple tasks efficiently are essential.
                                    </p>
                                    <div className="job-keys">
                                        <h5>Key Responsibilities:</h5>
                                        <ul>
                                            <li>Review, verify, and process invoices for payment in a timely manner, ensuring accuracy and compliance with company policies.</li>
                                            <li>Conduct regular reconciliations of vendor statements to ensure all transactions are accurately recorded.</li>
                                            <li>Assist in preparing and processing payments, including electronic transfers and checks, ensuring deadlines are met.</li>
                                            <li>Maintain organized records of invoices, payments, and related documents for audit purposes.</li>
                                            <li>Respond to vendor inquiries and resolve discrepancies related to invoices and payments promptly.</li>
                                        </ul>
                                    </div>
                                    <div className="job-requirement">
                                        <h5>Requirements:</h5>
                                        <ul>
                                            <li>Bachelor’s degree in Accounting, Finance, or a related field.</li>
                                            <li>2-3 years of experience in accounts payable or a similar role, with a strong understanding of the AP process.</li>
                                            <li>Proficiency in accounting software (e.g., Tally, QuickBooks) and Microsoft Excel.</li>
                                            <li>Strong attention to detail and accuracy in data entry and financial reporting.</li>
                                            <li>Excellent verbal and written communication skills for effective interaction with vendors and internal teams.</li>
                                        </ul>
                                    </div>
                                </div>
                                <a className="btn btn-primary rounded-pill py-3 px-5 mt-2" href="javascript:void(0);" onclick="toggleDescription(this)">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="jobs-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 jobs-column">
                        <div className="card jobs-card">
                            <div className="card-body">
                                <h4 className="card-title">Accounts Receivable Specialist</h4>
                                <h6>DEF Solutions Pvt. Ltd.</h6>
                                <div className="employee-info">
                                    <p><i className="fa-solid fa-bag-shopping"></i>&nbsp;&nbsp;&nbsp;<span>1-3 Yrs</span></p>
                                    <p><i className="fa-solid fa-rupee-sign"></i>&nbsp;&nbsp;&nbsp;<span>Not Disclosed</span></p>
                                    <p className="no-border"><i className="fa-solid fa-location-crosshairs"></i>&nbsp;&nbsp;&nbsp;Bangalore, India</p>
                                </div>
                                <p className="card-text mt-3">
                                    <i className="fa-regular fa-clipboard"></i>&nbsp;&nbsp;We have immediate openings for Accounts Receivable Specialists. Ideal for candidates with a background in invoicing and collections.
                                </p>
                                <div className="full-description">
                                    <p className="card-text">
                                        Successful candidates should possess strong analytical skills and the ability to manage customer relationships effectively. Experience in utilizing accounting software for tracking and reporting receivables is essential.
                                    </p>
                                    <div className="job-keys">
                                        <h5>Key Responsibilities:</h5>
                                        <ul>
                                            <li>Generate and send invoices to clients promptly, ensuring accuracy and compliance with company policies.</li>
                                            <li>Follow up on overdue accounts, communicating with clients to resolve payment issues and ensure timely collections.</li>
                                            <li>Regularly reconcile customer accounts, ensuring all transactions are accurately recorded and discrepancies are addressed.</li>
                                            <li>Prepare and maintain reports on accounts receivable status, aging reports, and collections metrics for management review.</li>
                                            <li>Build and maintain strong relationships with clients, addressing inquiries and providing support related to invoicing and payments.</li>
                                        </ul>
                                    </div>
                                    <div className="job-requirement">
                                        <h5>Requirements:</h5>
                                        <ul>
                                            <li>Bachelor’s degree in Accounting, Finance, or a related field.</li>
                                            <li>1-3 years of experience in accounts receivable, invoicing, or a related finance role.</li>
                                            <li>Proficiency in accounting software (e.g., QuickBooks, Tally) and Microsoft Excel for data analysis and reporting.</li>
                                            <li>Strong analytical skills to assess accounts and identify trends in collections and payment behaviors.</li>
                                            <li>Excellent verbal and written communication skills for effective interaction with clients and internal teams.</li>
                                        </ul>
                                    </div>
                                </div>
                                <a className="btn btn-primary rounded-pill py-3 px-5 mt-2" href="javascript:void(0);" onclick="toggleDescription(this)">Read More</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 jobs-column">
                        <div className="card jobs-card">
                            <div className="card-body">
                                <h4 className="card-title">Staff Accountant (Immediate Joiners)</h4>
                                <h6>GHI Enterprises</h6>
                                <div className="employee-info">
                                    <p><i className="fa-solid fa-bag-shopping"></i>&nbsp;&nbsp;&nbsp;<span>2-4 Yrs</span></p>
                                    <p><i className="fa-solid fa-rupee-sign"></i>&nbsp;&nbsp;&nbsp;<span>Not Disclosed</span></p>
                                    <p className="no-border"><i className="fa-solid fa-location-crosshairs"></i>&nbsp;&nbsp;&nbsp;Pune, India</p>
                                </div>
                                <p className="card-text mt-3">
                                    <i className="fa-regular fa-clipboard"></i>&nbsp;&nbsp;We have immediate openings for Staff Accountants with experience in journal entries and reconciliations.
                                </p>
                                <div className="full-description">
                                    <p className="card-text">
                                        Candidates should have a solid understanding of accounting principles and a commitment to maintaining accurate financial records. Strong analytical skills and the ability to work collaboratively in a fast-paced environment are essential.
                                    </p>
                                    <div className="job-keys">
                                        <h5>Key Responsibilities:</h5>
                                        <ul>
                                            <li>Prepare and post accurate journal entries to ensure proper recording of financial transactions in the general ledger.</li>
                                            <li>Perform regular reconciliations of balance sheet accounts, ensuring discrepancies are identified and resolved promptly.</li>
                                            <li>Assist in the preparation of monthly financial statements and reports, ensuring compliance with accounting standards.</li>
                                            <li>Support internal and external audits by providing necessary documentation and explanations for financial data.</li>
                                            <li>Collaborate with the accounting team to ensure timely and accurate month-end closing processes.</li>
                                        </ul>
                                    </div>
                                    <div className="job-requirement">
                                        <h5>Requirements:</h5>
                                        <ul>
                                            <li>Bachelor’s degree in Accounting, Finance, or a related field.</li>
                                            <li>2-4 years of relevant experience in accounting, with a focus on journal entries and reconciliations.</li>
                                            <li>Proficiency in accounting software (e.g., Tally, QuickBooks) and Microsoft Excel for financial analysis.</li>
                                            <li>Strong attention to detail and accuracy in data entry and financial reporting.</li>
                                            <li>Effective verbal and written communication skills for collaboration with team members and stakeholders.</li>
                                        </ul>
                                    </div>
                                </div>
                                <a className="btn btn-primary rounded-pill py-3 px-5 mt-2" href="javascript:void(0);" onclick="toggleDescription(this)">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="jobs-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 jobs-column">
                        <div className="card jobs-card">
                            <div className="card-body">
                                <h4 className="card-title">Payroll Specialist</h4>
                                <h6>JKL Technologies</h6>
                                <div className="employee-info">
                                    <p><i className="fa-solid fa-bag-shopping"></i>&nbsp;&nbsp;&nbsp;<span>3-5 Yrs</span></p>
                                    <p><i className="fa-solid fa-rupee-sign"></i>&nbsp;&nbsp;&nbsp;<span>Not Disclosed</span></p>
                                    <p className="no-border"><i className="fa-solid fa-location-crosshairs"></i>&nbsp;&nbsp;&nbsp;Noida, India</p>
                                </div>
                                <p className="card-text mt-3">
                                    <i className="fa-regular fa-clipboard"></i>&nbsp;&nbsp;We have immediate openings for Payroll Specialists with expertise in payroll processing and compliance.
                                </p>
                                <div className="full-description">
                                    <p className="card-text">
                                        Join our dynamic team to ensure accurate and timely payroll operations while navigating complex regulations. Your attention to detail will make a significant impact on our workforce!
                                    </p>
                                    <div className="job-keys">
                                        <h5>Key Responsibilities:</h5>
                                        <ul>
                                            <li>Process payroll for all employees, ensuring accuracy and compliance with relevant laws and regulations.</li>
                                            <li>Manage and maintain employee payroll records, including deductions, leave balances, and adjustments.</li>
                                            <li>Collaborate with HR to resolve payroll-related inquiries and discrepancies.</li>
                                            <li>Prepare and submit payroll-related reports, including tax filings and audits.</li>
                                            <li>Build and maintain strong relationships with clients, addressing inquiries and providing support related to invoicing and payments.</li>
                                        </ul>
                                    </div>
                                    <div className="job-requirement">
                                        <h5>Requirements:</h5>
                                        <ul>
                                            <li>Proficient in end-to-end payroll processing, including calculating wages, deductions, and bonuses.</li>
                                            <li>Strong understanding of local and national payroll regulations and labor laws to ensure compliance in all payroll activities.</li>
                                            <li>Ability to maintain high accuracy in payroll data management, ensuring that all entries are correct and discrepancies are resolved promptly.</li>
                                            <li>Familiarity with payroll software and tools, as well as MS Excel for data analysis and reporting.</li>
                                            <li>Excellent verbal and written communication skills to collaborate effectively with employees and address payroll inquiries or issues.</li>
                                        </ul>
                                    </div>
                                </div>
                                <a className="btn btn-primary rounded-pill py-3 px-5 mt-2" href="javascript:void(0);" onclick="toggleDescription(this)">Read More</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 jobs-column">
                        <div className="card jobs-card">
                            <div className="card-body">
                                <h4 className="card-title">Accounting Manager</h4>
                                <h6>MNO Group</h6>
                                <div className="employee-info">
                                    <p><i className="fa-solid fa-bag-shopping"></i>&nbsp;&nbsp;&nbsp;<span>7-10 Yrs</span></p>
                                    <p><i className="fa-solid fa-rupee-sign"></i>&nbsp;&nbsp;&nbsp;<span>Not Disclosed</span></p>
                                    <p className="no-border"><i className="fa-solid fa-location-crosshairs"></i>&nbsp;&nbsp;&nbsp;Chennai, India</p>
                                </div>
                                <p className="card-text mt-3">
                                    <i className="fa-regular fa-clipboard"></i>&nbsp;&nbsp;We are actively seeking skilled Accounting Managers who have a proven track record in leading teams and delivering accurate financial reporting.
                                </p>
                                <div className="full-description">
                                    <p className="card-text">
                                        Join us to drive strategic financial initiatives and enhance our reporting processes, ensuring compliance and efficiency across all operations.
                                    </p>
                                    <div className="job-keys">
                                        <h5>Key Responsibilities:</h5>
                                        <ul>
                                            <li>Oversee and mentor the accounting team, fostering a collaborative environment to achieve departmental goals.</li>
                                            <li>Prepare and present timely and accurate financial reports, ensuring compliance with accounting standards and regulations.</li>
                                            <li>Develop and manage budgets, providing insights and recommendations to support strategic planning and decision-making.</li>
                                            <li>Lead the preparation for internal and external audits, ensuring all financial records and processes are accurate and compliant.</li>
                                            <li>Identify areas for process enhancement within the accounting function, implementing best practices to improve efficiency and accuracy.</li>
                                        </ul>
                                    </div>
                                    <div className="job-requirement">
                                        <h5>Requirements:</h5>
                                        <ul>
                                            <li>7-10 years of experience in accounting or finance, with a focus on team management and financial reporting.</li>
                                            <li>Bachelor’s degree in Accounting, Finance, or a related field; a CPA or equivalent certification is preferred.</li>
                                            <li>Proficiency in accounting software and ERP systems, along with advanced Excel skills for financial analysis.</li>
                                            <li>Strong understanding of accounting principles, regulations, and compliance requirements in India.</li>
                                            <li>Excellent analytical and problem-solving abilities, with a keen attention to detail and accuracy in financial reporting.</li>
                                        </ul>
                                    </div>
                                </div>
                                <a className="btn btn-primary rounded-pill py-3 px-5 mt-2" href="javascript:void(0);" onclick="toggleDescription(this)">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="jobs-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 jobs-column">
                        <div className="card jobs-card">
                            <div className="card-body">
                                <h4 className="card-title">Tax Accountant (Immediate Joiners)</h4>
                                <h6>PQR Tax Advisors</h6>
                                <div className="employee-info">
                                    <p><i className="fa-solid fa-bag-shopping"></i>&nbsp;&nbsp;&nbsp;<span>4-6 Yrs</span></p>
                                    <p><i className="fa-solid fa-rupee-sign"></i>&nbsp;&nbsp;&nbsp;<span>Not Disclosed</span></p>
                                    <p className="no-border"><i className="fa-solid fa-location-crosshairs"></i>&nbsp;&nbsp;&nbsp;Hyderabad, India</p>
                                </div>
                                <p className="card-text mt-3">
                                    <i className="fa-regular fa-clipboard"></i>&nbsp;&nbsp;We are currently seeking Tax Accountants with a strong focus on corporate tax compliance and filings.
                                </p>
                                <div className="full-description">
                                    <p className="card-text">
                                        This role involves not only managing tax return preparation but also providing strategic guidance to optimize tax positions and ensure adherence to regulations. Join our team to play a key role in navigating complex tax landscapes and enhancing our overall tax strategy.
                                    </p>
                                    <div className="job-keys">
                                        <h5>Key Responsibilities:</h5>
                                        <ul>
                                            <li>Prepare and issue accurate invoices to clients in a timely manner, ensuring all billing information is correct.</li>
                                            <li>Monitor accounts receivable aging reports, follow up on overdue invoices, and manage collection activities.</li>
                                            <li>Communicate with clients regarding billing inquiries, payment terms, and account discrepancies to resolve issues promptly.</li>
                                            <li>Maintain accurate records of all transactions, payments, and communications related to accounts receivable.</li>
                                            <li>Generate and analyze reports on accounts receivable performance, providing insights to management on cash flow and collection trends.</li>
                                        </ul>
                                    </div>
                                    <div className="job-requirement">
                                        <h5>Requirements:</h5>
                                        <ul>
                                            <li>4-6 years of experience in accounts receivable, invoicing, or collections, preferably in a corporate environment.</li>
                                            <li>Bachelor’s degree in Accounting, Finance, or a related field is preferred.</li>
                                            <li>Strong attention to detail with the ability to maintain accurate records and identify discrepancies.</li>
                                            <li>Excellent verbal and written communication skills to effectively interact with clients and internal teams.</li>
                                            <li>Familiarity with accounting software and MS Excel for data management and reporting purposes.</li>
                                        </ul>
                                    </div>
                                </div>
                                <a className="btn btn-primary rounded-pill py-3 px-5 mt-2" href="javascript:void(0);" onclick="toggleDescription(this)">Read More</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 jobs-column">
                        <div className="card jobs-card">
                            <div className="card-body">
                                <h4 className="card-title">Internal Auditor</h4>
                                <h6>STU Consulting</h6>
                                <div className="employee-info">
                                    <p><i className="fa-solid fa-bag-shopping"></i>&nbsp;&nbsp;&nbsp;<span>3-5 Yrs</span></p>
                                    <p><i className="fa-solid fa-rupee-sign"></i>&nbsp;&nbsp;&nbsp;<span>Not Disclosed</span></p>
                                    <p className="no-border"><i className="fa-solid fa-location-crosshairs"></i>&nbsp;&nbsp;&nbsp;Delhi, India</p>
                                </div>
                                <p className="card-text mt-3">
                                    <i className="fa-regular fa-clipboard"></i>&nbsp;&nbsp;We are currently seeking Internal Auditors with expertise in risk assessment and financial audits. Ideal candidates will have a strong analytical mindset and the ability to evaluate internal controls, ensuring compliance and operational efficiency.
                                </p>
                                <div className="full-description">
                                    <p className="card-text">
                                        Join our team to help enhance our auditing processes and contribute to the overall integrity of our financial practices.</p>
                                    <div className="job-keys">
                                        <h5>Key Responsibilities:</h5>
                                        <ul>
                                            <li>Conduct comprehensive risk assessments to identify potential vulnerabilities and areas for improvement within the organization.</li>
                                            <li>Plan and execute financial audits, ensuring accuracy and compliance with relevant regulations and standards.</li>
                                            <li>Assess and evaluate the effectiveness of internal controls, making recommendations for enhancements where necessary.</li>
                                            <li>Prepare detailed audit reports outlining findings, risks, and recommendations for management and stakeholders.</li>
                                            <li>Monitor the implementation of audit recommendations and follow up on corrective actions to ensure compliance and efficiency.</li>
                                        </ul>
                                    </div>
                                    <div className="job-requirement">
                                        <h5>Requirements:</h5>
                                        <ul>
                                            <li>3-5 years of experience in internal auditing, risk management, or financial auditing.</li>
                                            <li>Bachelor’s degree in Accounting, Finance, or a related field; a professional certification (e.g., CIA, CPA) is a plus.</li>
                                            <li>Strong analytical and problem-solving skills, with the ability to assess complex information and identify key issues.</li>
                                            <li>Excellent verbal and written communication skills for effective reporting and collaboration with various departments.</li>
                                            <li>Meticulous attention to detail and a commitment to accuracy in all aspects of auditing and reporting.</li>
                                        </ul>
                                    </div>
                                </div>
                                <a className="btn btn-primary rounded-pill py-3 px-5 mt-2" href="javascript:void(0);" onclick="toggleDescription(this)">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="jobs-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 jobs-column">
                        <div className="card jobs-card">
                            <div className="card-body">
                                <h4 className="card-title">Bookkeeper (Immediate Joiners)</h4>
                                <h6>XYZ Bookkeeping Services</h6>
                                <div className="employee-info">
                                    <p><i className="fa-solid fa-bag-shopping"></i>&nbsp;&nbsp;&nbsp;<span>2-4 Yrs</span></p>
                                    <p><i className="fa-solid fa-rupee-sign"></i>&nbsp;&nbsp;&nbsp;<span>Not Disclosed</span></p>
                                    <p className="no-border"><i className="fa-solid fa-location-crosshairs"></i>&nbsp;&nbsp;&nbsp;Ahmedabad, India</p>
                                </div>
                                <p className="card-text mt-3">
                                    <i className="fa-regular fa-clipboard"></i>&nbsp;&nbsp;We are currently seeking Bookkeepers with expertise in maintaining financial records and reconciliations. 
                                </p>
                                <div className="full-description">
                                    <p className="card-text">
                                         Ideal candidates will have a strong understanding of accounting principles and a keen eye for detail, ensuring accuracy in all financial transactions. Join our team to play a vital role in supporting our financial operations and enhancing the overall integrity of our records.
                                    </p>
                                    <div className="job-keys">
                                        <h5>Key Responsibilities:</h5>
                                        <ul>
                                            <li>Accurately record daily financial transactions and maintain updated financial records.</li>
                                            <li>Perform regular bank and account reconciliations to ensure accuracy and resolve discrepancies promptly.</li>
                                            <li>Manage accounts payable and receivable processes, including invoice processing and payment tracking.</li>
                                            <li>Assist in preparing financial statements and reports for management review, ensuring compliance with accounting standards.</li>
                                            <li>Build and maintain strong relationships with clients, addressing inquiries and providing support related to invoicing and payments.</li>
                                        </ul>
                                    </div>
                                    <div className="job-requirement">
                                        <h5>Requirements:</h5>
                                        <ul>
                                            <li>2-4 years of experience in bookkeeping or accounting, with a solid understanding of financial principles.</li>
                                            <li>Bachelor’s degree in Accounting, Finance, or a related field is preferred.</li>
                                            <li>Proficiency in accounting software (e.g., QuickBooks, Tally) and MS Excel for data management and reporting.</li>
                                            <li>Strong attention to detail and accuracy in financial reporting and record-keeping.</li>
                                            <li>Good verbal and written communication skills for effective collaboration with team members and clients.</li>
                                        </ul>
                                    </div>
                                </div>
                                <a className="btn btn-primary rounded-pill py-3 px-5 mt-2" href="javascript:void(0);" onclick="toggleDescription(this)">Read More</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 jobs-column">
                        <div className="card jobs-card">
                            <div className="card-body">
                                <h4 className="card-title">Cost Accountant</h4>
                                <h6>ABC Manufacturing Pvt. Ltd.</h6>
                                <div className="employee-info">
                                    <p><i className="fa-solid fa-bag-shopping"></i>&nbsp;&nbsp;&nbsp;<span>4-6 Yrs</span></p>
                                    <p><i className="fa-solid fa-rupee-sign"></i>&nbsp;&nbsp;&nbsp;<span>Not Disclosed</span></p>
                                    <p className="no-border"><i className="fa-solid fa-location-crosshairs"></i>&nbsp;&nbsp;&nbsp;Kolkata, India</p>
                                </div>
                                <p className="card-text mt-3">
                                    <i className="fa-regular fa-clipboard"></i>&nbsp;&nbsp;We are currently seeking Cost Accountants with expertise in cost management and variance analysis. The ideal candidates will have a strong analytical background and the ability to provide insights that drive efficiency and profitability. 
                                </p>
                                <div className="full-description">
                                    <p className="card-text">
                                        Join our team to play a crucial role in budgeting, forecasting, and optimizing cost structures across our operations.</p>
                                    <div className="job-keys">
                                        <h5>Key Responsibilities:</h5>
                                        <ul>
                                            <li>Analyze and manage costs associated with manufacturing processes, ensuring adherence to budgetary constraints.</li>
                                            <li>Conduct regular variance analysis to identify discrepancies between budgeted and actual costs, providing actionable insights to management.</li>
                                            <li>Assist in the preparation of budgets and forecasts, ensuring accurate projections based on historical data and market trends.</li>
                                            <li>Prepare and maintain reports on accounts receivable status, aging reports, and collections metrics for management review.</li>
                                            <li>Collaborate with cross-functional teams to identify opportunities for cost reduction and efficiency improvements in operations.</li>
                                        </ul>
                                    </div>
                                    <div className="job-requirement">
                                        <h5>Requirements:</h5>
                                        <ul>
                                            <li>4-6 years of experience in cost accounting or management accounting within a manufacturing environment.</li>
                                            <li>Bachelor’s degree in Accounting, Finance, or a related field; a professional certification (e.g., CMA, CPA) is preferred.</li>
                                            <li>Strong analytical and problem-solving skills, with the ability to interpret financial data and derive meaningful conclusions.</li>
                                            <li>Proficient in accounting software and ERP systems, along with advanced Excel skills for data analysis and reporting.</li>
                                            <li>Excellent verbal and written communication skills, enabling effective collaboration with team members and management.</li>
                                        </ul>
                                    </div>
                                </div>
                                <a className="btn btn-primary rounded-pill py-3 px-5 mt-2" href="javascript:void(0);" onclick="toggleDescription(this)">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
               
        {/* <!-- jobs section Ends --> */}


        {/* <!-- pagination section --> */}
        <div className="demo">
            <nav className="pagination-outer" aria-label="Page navigation">
                <ul className="pagination">
                    <li className="page-item">
                        <a href="#" className="page-link" aria-label="Previous">
                            <span aria-hidden="true">«</span>
                        </a>
                    </li>
                    <li className="page-item active"><a className="page-link" href="#">1</a></li>
                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                    <li className="page-item "><a className="page-link" href="#">3</a></li>
                    <li className="page-item"><a className="page-link" href="#">4</a></li>
                    <li className="page-item"><a className="page-link" href="#">5</a></li>
                    <li className="page-item">
                        <a href="#" className="page-link" aria-label="Next">
                            <span aria-hidden="true">»</span>
                        </a>
                    </li>
                </ul>
            </nav> 
        </div>

        {/* <!-- pagination section ends --> */}

        </>
    );
}

export default Jobs;
