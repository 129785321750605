import React from 'react';

const TestRecords = ({data}) => {
    return (
        <table id="example1" className="table table-bordered table-striped">
        <thead>
            <tr>
            <th>S.No.</th>
            <th>Lesson</th>
            <th>Title</th>
            <th>Time Allowed </th>
            <th>Total Questions </th>
            <th>Max Attempts</th>
            <th>Status</th>
            </tr>
        </thead>
        <tbody>
             {data.length>0 ?data.map(function(data,index) {
                 return ( 
                    <tr key={data.id}>
                        <td>{index+1}</td>
                        <td style={{textTransform:"capitalize"}}>{data.lession_title}</td>
                        <td style={{textTransform:"capitalize"}}>{data.title}</td>
                        <td >{data.time_allowed}</td>
                        <td >{data.total_questions} </td>
                        <td >{data.max_attempt} </td>
                        <td>
                            <button type="button" className="btn btn-success btn-sm">Active</button>
                        </td>
                    </tr> 
                ) 
                }): <tr><td colSpan={6} >No records available.</td></tr>} 
            </tbody>
        <tfoot>
            <tr>
            <th>S.No.</th>
            <th>Lesson</th>
            <th>Title</th>
            <th>Time Allowed </th>
            <th>Total Questions </th>
            <th>Max Attempts</th>
            <th>Status</th>
            </tr>
        </tfoot>
        </table>
    );
}

export default TestRecords;
