import React from 'react';
const Services = () => {
    return (
        <>
             <div>
                <h2>Services</h2>
            </div>
        </>
    );
}

export default Services;
