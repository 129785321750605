import React ,{ useEffect } from 'react';
import Layout from './Layouts/Website/Layout';

const App = () => {
  useEffect(() => {
    document.title = 'Accountants club - Home';
  }, []);
  return (
        <>
            {/* <!-- Carousel Start --> */}
            <div className="carousel-header">
                <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-bs-target="#carouselId" data-bs-slide-to="0" className="active"></li>
                        <li data-bs-target="#carouselId" data-bs-slide-to="1"></li>
                        <li data-bs-target="#carouselId" data-bs-slide-to="2"></li>
                    </ol>
                    <div className="carousel-inner" role="listbox">
                        <div className="carousel-item active">
                            <img src="new assets/img/Technology-In-Accounting.webp" className="img-fluid" alt="Image"/>
                            <div className="carousel-caption">
                                <div className="p-3" >
                                    
                                    <h2 className="display-2 text-capitalize text-white mb-4">Trusted Accounting Solutions for Your Business</h2>
                                    <p className="mb-5 fs-5">Our expert team delivers tailored services for payroll, financial analysis, and more, ensuring transparency and timely reporting to optimize cash flow and support growth. 
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="new assets/img/accounting & tax.webp" className="img-fluid" alt="Image"/>
                            <div className="carousel-caption">
                                <div className="p-3" >
                                    
                                    <h2 className="display-2 text-capitalize text-white mb-4">Smart Accounting Solutions</h2>
                                    <p className="mb-5 fs-5">Unlock the potential of your business with our precise bookkeeping, tax preparation, and insightful financial reporting. We handle the numbers, so you can focus on what truly matters—growing your business! 
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="new assets/img/tally prime in accounting.png" className="img-fluid" alt="Image"/>
                            <div className="carousel-caption">
                                <div className="p-3" >
                                    
                                    <h2 className="display-2 text-capitalize text-white mb-4">Your Partner in Financial Success</h2>
                                    <p className="mb-5 fs-5">Navigate your financial journey with our expert accounting services. From meticulous bookkeeping to smart tax strategies, we empower you to focus on growth while we handle the numbers!
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon btn bg-primary" aria-hidden="false"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
                        <span className="carousel-control-next-icon btn bg-primary" aria-hidden="false"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            {/* <!-- Carousel End --> */}
        {/* <!-- Packages Start --> */}
        <div className="container-fluid packages py-5">
            <div className="container py-5">
                <div className="packages-section mx-auto text-center mb-5">
                    <h5 className="section-title px-3">Courses</h5>
                    <h1 className="mb-0">Awesome Courses</h1>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="packages-item">
                                <div className="packages-img">
                                    <img src="new assets/img/Payroll.jpg" className="img-fluid w-100 rounded-top" alt="Image"/>
                                </div>
                                <div className="packages-content bg-light">
                                    <div className="p-4 pb-0">
                                        <h5 className="mb-0">Pay Rolls</h5>
                                        <p className="mb-4">Efficient payroll processing for accurate salary calculations and compliance with labor laws, freeing you to focus on your business.</p>
                                    </div>
                                    <div className="row bg-primary rounded-bottom mx-0">
                                        <div className="col-6 text-start px-0">
                                            <a href="#" className="btn-hover btn text-white py-2 px-4">Read More</a>
                                        </div>
                                        <div className="col-6 text-end px-0">
                                            <a href="#" className="btn-hover btn text-white py-2 px-4">Enroll Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="packages-item">
                                <div className="packages-img">
                                    <img src="new assets/img/ACCOUNT.webp" className="img-fluid w-100 rounded-top" alt="Image"/>
                                </div>
                                <div className="packages-content bg-light">
                                    <div className="p-4 pb-0">
                                        <h5 className="mb-0">Income Tax and TDS</h5>                               
                                        <p className="mb-4">Our income tax services ensure accurate filing and maximize deductions, helping you stay compliant while minimizing your tax liabilities.</p>
                                    </div>
                                    <div className="row bg-primary rounded-bottom mx-0">
                                        <div className="col-6 text-start px-0">
                                            <a href="#" className="btn-hover btn text-white py-2 px-4">Read More</a>
                                        </div>
                                        <div className="col-6 text-end px-0">
                                            <a href="#" className="btn-hover btn text-white py-2 px-4">Enroll Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="packages-item">
                                <div className="packages-img">
                                    <img src="new assets/img/GST ACC.png" className="img-fluid w-100 rounded-top" alt="Image"/>
                                </div>
                                <div className="packages-content bg-light">
                                    <div className="p-4 pb-0">
                                        <h5 className="mb-0">Goods and Services Tax (GST)</h5>
                                        <p className="mb-4">Simplify your tax compliance with our GST services, ensuring accurate filings and timely submissions to meet all regulatory requirements.</p>
                                    </div>
                                    <div className="row bg-primary rounded-bottom mx-0">
                                        <div className="col-6 text-start px-0">
                                            <a href="#" className="btn-hover btn text-white py-2 px-4">Read More</a>
                                        </div>
                                        <div className="col-6 text-end px-0">
                                            <a href="#" className="btn-hover btn text-white py-2 px-4">Enroll Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Packages End --> */}
        </>
  );
} 

export default App;
