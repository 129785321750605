import React from 'react'
import Moment from 'react-moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
const Records = ({data}) => {
    var userdata = JSON.parse(sessionStorage.getItem('LoggedInUser'));
const updatestatus = (e)=>{
    // if (e.target.checked == true) {
    //     console.log(e.target.value)
        
    //   } else {
    //     console.log(e.target.value)
    //   }
      axios({
        method: "post",
        url: global.config.ApiUri+"updatestatus",
        data: {'id':e.target.value,'status':e.target.checked},
        headers: {
          "Accept": "application/json",
           "Authorization": `Bearer ${userdata.access_token}`,
          },
        })
        .then(function (response) {
            //handle success
            // console.log(response)
            switch (response.status) {
                case 200:
                    toast.success(response.data.message);
                   
                    break;
                default:
                    break;
            }
        })
        .catch(function (response) {
            //handle error
            // console.log(response);
            switch (response.status) {
                case 422:
                    toast.error(response.response.message);
                   
                    break;
                default:
                    break;
            }
        });
}
  return (  
    <>
    <ToastContainer autoClose={1000} containerId={"friendRequest"} />
    <table id="example1" className="table table-bordered table-striped">
         
    <thead>
        <tr>
        <th>S.No.</th>
        <th>Name</th>
        <th>Email</th>
        <th>Role</th>
        <th>Created At</th>
        <th>Status</th>
        </tr>
    </thead>
    <tbody>
            {data.length>0 ?data.map(function(data,index) {
             return ( 
                <tr key={data.id}>
                    <td>{index+1}</td>
                    <td style={{textTransform:"capitalize"}}>{data.name}</td>
                    <td>{data.email}</td>
                    <td style={{textTransform:"capitalize"}}>{data.role}</td>
                    <td>
                        <Moment format="DD-MM-YYYY HH:mm A">{data.created_at}</Moment>
                    </td>
                    <td>
                        <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                        {data.status_id == 1?<input type="checkbox" className="custom-control-input" value={data.id} onChange={updatestatus} id={`customSwitch3${index}`}  defaultChecked/>
                        :
                        <input type="checkbox" className="custom-control-input" value={data.id} onChange={updatestatus} id={`customSwitch3${index}`}  />}
                        
                        <label className="custom-control-label" htmlFor={`customSwitch3${index}`}></label>
                        </div>
                        {/* <button type="button" className="btn btn-success btn-sm">Active</button> */}
                    </td>
                </tr> 
            ) 
            }): <tr><td colSpan={6} >No records available.</td></tr>} 
        </tbody>
    <tfoot>
        <tr>
            <th>S.No.</th>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Created At</th>
            <th>Status</th>
        </tr>
    </tfoot>
    </table>
    </>
    
  ) 
}

export default Records  