import React from 'react';
import { Link } from 'react-router-dom';
const DashboardPageNotFound = () => {
    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Dashboard</h1>
                    </div>
                    {/* <!-- /.col --> */}
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item active">Dashboard</li>
                        </ol>
                    </div>
                    </div>
                </div>
                </div>



                <section className="content">
                {/* <!-- 404 Start --> */}
                    <div className="container-fluid py-5 empty-page">
                        <div className="container py-5 text-center">
                            <div className="row justify-content-center">
                                <div className="col-lg-6">
                                    <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
                                    <h1 className="display-1">404</h1>
                                    <h1 className="mb-4 text-dark">Page Not Found</h1>
                                    <p className="mb-4 text-dark">We’re sorry, the page you have looked for does not exist in our website! Maybe go to our home page or try to use a search?</p>
                                    <Link className="btn btn-primary rounded-pill py-3 px-5" to="/dashboard">Go Back To Home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- 404 End --> */}
                </section>
        </>
    );
}

export default DashboardPageNotFound;
