import React , { useState } from 'react';
import axios from 'axios';
import { useFormInputValidation } from "react-form-input-validation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AddTest = () => {
    const [data, setData] = useState([]);
    const [checked, setChecked] = useState(false);
    const [startspinner, setstartspinner] = useState('stopspinnercls');
    const sessiontoken = JSON.parse(sessionStorage.getItem('LoggedInUser'));
    React.useEffect(() => {
        const sessiontoken = JSON.parse(sessionStorage.getItem('LoggedInUser'));
        axios({
            method: "post",
            url: global.config.ApiUri+"getlessonsfortest",
            data: '',
            headers: {'Content-Type': 'application/json','Authorization': `Bearer ${sessiontoken.access_token}`},
            })
            .then(function (response) {
                // console.log(response.data.data)
                switch (response.status) {
                    case 200:
                        setData(response.data.data);
                        break;
                    default:
                        break;
                }
            })
            .catch(function (response) {
            });
    }, []);

    const [fields, errors, form] = useFormInputValidation({
        lesson: "",
        title: "",
        time_allowed: "",
        total_questions:"",
        max_attempt:"",
        pass_percentage:"",
        result_reclar:checked,
        other_info:"",
      }, {
        lesson: "required",
        title: "required",
        time_allowed: "required|numeric",
        total_questions: "required|numeric",
        max_attempt: "required|numeric",
        pass_percentage: "required|numeric",
        result_reclar: "required",
        other_info: "required"
      });

    const addTest =async (event) => {
        console.log(fields)
        const isValid = await form.validate(event);
        if (isValid) {
          setstartspinner('startspinnercls') ;
          // console.log(fields, errors);
              axios({
              method: "post",
              url: global.config.ApiUri+"addTest",
              data: fields,
              headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${sessiontoken.access_token}`,
                },
              })
              .then(function (response) {
                  //handle success
                  // console.log(response)
                  switch (response.status) {
                      case 200:
                          toast.success(response.data.data.message);
                          setstartspinner('stopspinnercls') ;
                          break;
                      default:
                          break;
                  }
              })
              .catch(function (response) {
                  //handle error
                  // console.log(response);
                  switch (response.status) {
                      case 422:
                          toast.error(response.response.data.message);
                          setstartspinner('stopspinnercls') ;
                          break;
                      default:
                          break;
                  }
              });
          }
    }
    return (
          <>
          <ToastContainer autoClose={1000} containerId={"friendRequest"} />
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>Create Test</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="../../index.html">Home</a></li>
                        <li className="breadcrumb-item active">Create Test</li>
                        </ol>
                    </div>
                    </div>
                </div>
                </section>
                <section className="content">
                <div className="container-fluid">
                    <div className="row">
                    <div className="col-md-6">
                        <div className="card card-info">
                        <div className="card-header">
                            <h3 className="card-title">Create Here</h3>
                        </div>
                        <form className="form-horizontal" onSubmit={addTest}>
                            <div className="card-body">
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Lesson Name:</label>
                                <div className="col-sm-8">
                                <select className="form-control select2" name='lesson' onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.lesson}>
                                <option value="">--Select--</option>
                                {data.map((item,key)=>
                                { return(
                                    <option style={{textTransform:"capitalize"}}  value={item.lession_no} key={key}>{key+1}. (Lesson No. {item.lession_no}):- {item.lession_title} | ({item.title})</option>
                                )})}
                                </select>
                                {errors.lesson ? <label className="error"> {errors.lesson ? errors.lesson : ""} </label> : ""}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Topic:</label>
                                <div className="col-sm-8">
                                <input className="form-control" id="sec-name" placeholder="Topic Name" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.title} name='title'/>
                                {errors.title ? <label className="error"> {errors.title ? 'The topic field is required.' : ""} </label> : ""}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Time Allowed:</label>
                                <div className="col-sm-8">
                                <input className="form-control"  id="sec-name"  placeholder="Time Allowed" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.time_allowed} name='time_allowed'/>
                                {errors.time_allowed ? <label className="error"> {errors.time_allowed ? errors.time_allowed : ""} </label> : ""}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Total Questions:</label>
                                <div className="col-sm-8">
                                <input className="form-control" id="sec-name" placeholder="Total Questions" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.total_questions} name='total_questions'/>
                                {errors.total_questions ? <label className="error"> {errors.total_questions ? errors.total_questions : ""} </label> : ""}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Maximum Attempts:</label>
                                <div className="col-sm-8">
                                <input className="form-control" id="sec-name" placeholder="Maximum Attempts" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.max_attempt} name='max_attempt'/>
                                {errors.max_attempt ? <label className="error"> {errors.max_attempt ? errors.max_attempt : ""} </label> : ""}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="inputEmail3" className="col-sm-4 col-form-label">Passing Percentage:</label>
                                <div className="col-sm-8">
                                <input className="form-control" id="sec-name" placeholder="Passing Percentage" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.pass_percentage} name='pass_percentage'/>
                                {errors.pass_percentage ? <label className="error"> {errors.pass_percentage ? "The passing percentage field is required." : ""} </label> : ""}
                                </div>
                            </div>
                            <div className="form-group row align-items-center">
                                <label htmlFor="inputPassword3" className="col-sm-4 col-form-label">Result Declaration:</label>
                                <div className="custom-control col-md-4 custom-radio">
                                <input className="custom-control-input" type="radio" id="customRadio1" name="result_reclar" value="Immimmediate"/>
                                <label htmlFor="customRadio1" className="custom-control-label">Immediate</label>
                                </div>
                                <div className="custom-control col-md-4 custom-radio">
                                <input className="custom-control-input" type="radio" id="customRadio2" name="result_reclar" value="After Review" defaultChecked/>
                                <label htmlFor="customRadio2" className="custom-control-label">After review</label>
                                </div>
                                {errors.result_reclar ? <label className="error"> {errors.result_reclar ? errors.result_reclar : ""} </label> : ""}
                            </div>
                            <div className="form-group row">
                                <label htmlFor="description" className="col-sm-4 col-form-label">Other information:</label>
                                <div className="col-sm-8">
                                    <textarea className="form-control" id="description" placeholder="Other information" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.other_info} name='other_info' rows="4"></textarea>
                                    {errors.other_info ? <label className="error"> {errors.other_info ? "The other information field is required." : ""} </label> : ""}
                                </div>
                                </div>
                            </div>
                            <div className="card-footer">
                            <input type="submit" className="btn btn-info" name='Submit' />
                            {/* <button type="submit" className="btn btn-default float-right">Cancel</button> */}
                            </div>

                        </form>
                        </div>
                    </div>
                    </div>
                </div>
                </section>
                </>
    );
}

export default AddTest;
