import React ,{useState , useEffect} from 'react';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  { useNavigate} from "react-router-dom";

// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Header = () => {
    const navigate = useNavigate();
    const [authdata, setauthdata] = useState(0);
    useEffect(()=>{
        if(!sessionStorage.getItem('LoggedInUser')){
            setauthdata(0);
        }else{
            setauthdata(1);
        }
    },[]);
    
    
   
   
    function Logout(){
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to logout?",
            icon: "warning",
            dangerMode: true,
          })
          .then(willDelete => {
            if (willDelete) {
                toast.success('Logout Successfully.');
                setTimeout(() => {
                    sessionStorage.clear();
                    navigate('/');
                  }, 2000)
            }
          });
    }
     
    return (
        <>
      
    
        <ToastContainer autoClose={1000} />
            
        <link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css" rel="stylesheet"/>

    
    <link href="new assets/lib/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet"/>
    <link href="new assets/lib/lightbox/css/lightbox.min.css" rel="stylesheet"/>

    <link href="new assets/css/custom.css?v=dfg4fd" rel="stylesheet"/>
    
    <link href="new assets/css/bootstrap.min.css?v=dfgfd" rel="stylesheet"/>


    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css" integrity="sha512-Kc323vGBEqzTmouAECnVceyQqyqdsSiqLQISBL29aUW4U/M7pSPA/gEUZQqv1cwx4OnYxTxve5UMg5GT6L4JJg==" crossOrigin="anonymous" referrerPolicy="no-referrer" />

   
    <link href="new assets/css/style.css?v=dgfd" rel="stylesheet"/>
        {/* <!-- Topbar Start --> */}
        <div className="container-fluid bg-primary px-5 d-none d-lg-block">
            <div className="row gx-0">
                <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
                    <div className="d-inline-flex align-items-center" >
                        <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i className="fab fa-twitter fw-normal"></i></a>
                        <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                        <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                        <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href=""><i className="fab fa-instagram fw-normal"></i></a>
                        <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle" href=""><i className="fab fa-youtube fw-normal"></i></a>
                    </div>
                </div>
                <div className="col-lg-4 text-center text-lg-end">
                    <div className="d-inline-flex align-items-center" >
                    {authdata ===0?(
                        <div className="d-inline-flex align-items-center" >
                            <div className="dropdown">
                                <a href="#" className="text-light" data-bs-toggle="dropdown">
                                    <small className="me-3"><i className="fa fa-user me-2"></i>Register</small>
                                </a>
                                <div className="dropdown-menu rounded">
                                    <Link to="/register" state={{ usertype: 'Student' }} className="dropdown-item"><i className="fas fa-user-graduate me-2"></i>Student</Link>
                                    <Link to="/register" state={{ usertype: 'Employer' }} className="dropdown-item"><i className="fas fa-user-tie me-2"></i>Employee</Link>
                                </div>
                            </div>
                            <div className="dropdown top-login">
                                <a href="#" className="text-light" data-bs-toggle="dropdown"><small><i className="fa fa-sign-in-alt me-2"></i>Login</small></a>
                                <div className="dropdown-menu rounded">
                                    <Link to="/login" state={{ usertype: 'Student' }} className="dropdown-item "><i className="fas fa-user-graduate me-2"></i>Student</Link>
                                    <Link to="/login" state={{ usertype: 'Employer' }} className="dropdown-item"><i className="fas fa-user-tie me-2"></i>Employee</Link>
                                </div>
                            </div>
                        </div>):''}
                        {authdata === 1?(
                            <div>
                                <Link to="/dashboard" className="dropdown-item text-light"><i className="fas fa-home me-2"></i> Dashboard </Link>
                            </div>
                            
                        // <div className="dropdown">
                        //     <a href="#" className="dropdown-toggle text-light" data-bs-toggle="dropdown"><small><i className="fa fa-home me-2"></i> My Dashboard</small></a>
                        //     <div className="dropdown-menu rounded">
                        //         <Link to="/dashboard" className="dropdown-item text-light"><i className="fas fa-home me-2"></i> Dashboard </Link>
                        //         {/* <Link onClick={Logout} className="dropdown-item"><i className="fas fa-power-off me-2"></i> Log Out</Link> */}
                                
                        //     </div>
                        // </div>
                        ):''}
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Topbar End --> */}
        </>
    );
}

export default Header;
