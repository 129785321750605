import React, {useEffect} from 'react'; 
import  { useNavigate} from "react-router-dom";
const AuthMiddleware = () => {
    const navigate = useNavigate();
    useEffect(()=>{
        if(!sessionStorage.getItem('LoggedInUser')){
            navigate("/");
        }else{
            navigate("/dashboard");
        }
        },[]);
    return (
        <>
           
        </>
    );
}

export default AuthMiddleware;
