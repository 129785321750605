import React from 'react';
import { Link } from 'react-router-dom';
const PageNotFound = () => {
    return (
        <>
           {/* <!-- Header Start --> */}
            <div class="container-fluid bg-breadcrumb">
                <div class="container text-center py-5">
                    <h3 class="text-white display-3 mb-4">404 Page</h3>
                    <ol class="breadcrumb justify-content-center mb-0">
                        {/* <li class="breadcrumb-item"><Link href="index.html">Home</a></li> */}
                        {/* <!-- <li class="breadcrumb-item"><a href="#">Pages</a></li> --> */}
                        <li class="breadcrumb-item active text-white">404 Page</li>
                    </ol>    
                </div>
            </div>
            {/* <!-- Header End -->

            <!-- 404 Start --> */}
            <div class="container-fluid py-5">
                <div class="container py-5 text-center">
                    <div class="row justify-content-center">
                        <div class="col-lg-6">
                            <i class="bi bi-exclamation-triangle display-1 text-primary"></i>
                            <h1 class="display-1">404</h1>
                            <h1 class="mb-4 text-dark">Page Not Found</h1>
                            <p class="mb-4 text-dark">We’re sorry, the page you have looked for does not exist in our website! Maybe go to our home page or try to use a search?</p>
                            <Link class="btn btn-primary rounded-pill py-3 px-5" to="/">Go Back To Home</Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- 404 End --> */}
        </>
    );
}

export default PageNotFound;
