import React from 'react';
const VerifyCertificate = () => {
    return (
        <>
              {/* <!-- Header Start --> */}
        <div className="container-fluid bg-breadcrumb-verify">
            <div className="container breadcrumb-center text-center py-5">
                <h3 className="text-white display-3 mb-4">Verify Certificate</h3>
                <ol className="breadcrumb justify-content-center mb-0">
                    <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                    <li className="breadcrumb-item active text-white">Verify Certificate</li>
                </ol>    
            </div>
        </div>
        {/* <!-- Header End --> */}

        {/* <!-- Tour Booking Start --> */}
        <div className="container-fluid booking">
            <div className="container py-5">
                <div className="row g-5 align-items-center">
                    <div className="col-md-6 col-12">
                        <img className="audit-image" src="new assets/img/audit.jpg" alt='audit' width="550"/>
                    </div>
                    <div className="col-md-6">
                        <div className="certificate-heading">
                            <h1 className="text-white mb-3">Verify Certificate</h1>      
                        </div>               
                        <form>                                                      
                            <div className="form-floating">
                                <input type="text" className="form-control bg-white border-0" id="name" placeholder="Your Name"/>
                                <label htmlFor="name">Certificate No.</label>
                            </div>            
                            <button className="btn btn-primary verify-btn text-white py-3" type="submit">Verify</button>                                                                                 
                        </form>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Tour Booking End --> */}

        </>
    );
}

export default VerifyCertificate;
