import React from 'react';

const Preloader = () => {
    return (
        <>
       
        </>
    );
}

export default Preloader;
