import React , {useState}from 'react';
import axios from 'axios';
import Records from '../../Components/Records';
import Pagination from '../../Components/Pagination';

const AllUsers = () => {
    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    React.useEffect(() => {
        const sessiontoken = JSON.parse(sessionStorage.getItem('LoggedInUser'));
        axios({
            method: "post",
            url: global.config.ApiUri+"getusers",
            data: '',
            headers: {'Content-Type': 'application/json','Authorization': `Bearer ${sessiontoken.access_token}`},
            })
            .then(function (response) {
                // console.log(response.data.data)
                switch (response.status) {
                    case 200:
                        setData(response.data.data)
                        
                        break;
                    default:
                        break;
                }
            })
            .catch(function (response) {
            });
      }, []);
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(data.length / recordsPerPage)

    return (
        <>
            
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1>All Users</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                            <a href="#">Home</a>
                        </li>
                        <li className="breadcrumb-item active">All Users</li>
                        </ol>
                    </div>
                    </div>
                </div>
                </section>
                <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12"> {/*
                            <!-- /.card --> */} <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">All Users List</h3>
                            </div> {/*
                            <!-- /.card-header --> */}
                             <div className="card-body">
                             <Records data={currentRecords}/>
                            </div> {/*
                            <!-- /.card-body --> */}
                            <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                            </div> {/*
                            <!-- /.card --> */}
                        </div> {/*
                    <!-- /.col --> */}
                    </div> {/*
                    <!-- /.row --> */}
                </div> {/*
                <!-- /.container-fluid --> */}
                </section> {/*
                <!-- /.content --> */}
                
        </>
    );
}

export default AllUsers;
